// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "utils/axios";
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";
import { appInsights } from "../../utils/ApplicationInsightsService";
import { PowerBIReportState } from "../../components/PowerBI/PowerBI.helpers";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import {
  KIOSK_HEALTH_CHECK,
  KIOSK_HEALTH_METRICS,
  KIOSK_LOCATION_WARNING,
} from "../../utils/constants/kiosk";

// ----------------------------------------------------------------------

export const initialState = {
  kioskStatus: null,
  kioskName: storage.getKioskName() ? storage.getKioskName() : null,
  kioskAppVersion: storage.getLocusAppVersion()
    ? storage.getLocusAppVersion()
    : null,
  kioskUrl: storage.getKiosk() ? storage.getKiosk() : null,
  activeUser: null,
  username: "",
  selectedDashboard: null,
  selectedSite: null,
  kioskHealthPingCount: 0,
  powerBiLastStatus: "",
  powerBiLoaded: false,
  powerBiRendered: false,
  powerBiRenderedCount: 0,
  powerBiErrorCount: 0,
  powerBiErrors: [],
};

const slice = createSlice({
  name: "kiosk",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    updateKioskStatus(state, action) {
      // const currentState = { ...state };
      const activeUser = action.payload.activeUser || null;
      const selectedDashboard = action.payload.selectedDashboard || null;
      const selectedSite = action.payload.selectedSite || null;
      // console.log("currentState: ", currentState);
      // console.log("updateKioskStatus: ", action.payload);

      if (activeUser) {
        state.activeUser = activeUser.id;
        state.username = activeUser.username;
      }

      if (selectedDashboard) {
        state.selectedDashboard = selectedDashboard.name;
        state.refreshRate = selectedDashboard.refreshRate;
        state.reportId = selectedDashboard.reportId;
        state.refreshRate = selectedDashboard.refreshRate;
      }

      if (selectedSite) {
        state.selectedSite = selectedSite.id;
        state.siteName = selectedSite.name;
        state.friendlyName = selectedSite.friendlyName;
      }

      if (action.payload.location) {
        state.location = action.payload.location;
      }

      state.kioskStatus = action.payload.status;

      // const nextState = { ...state };

      // console.log({ name: `KioskStatus` }, nextState);
      // appInsights.trackEvent({ name: `KioskStatus` }, nextState)
    },

    updateKioskHealthPing(state, action) {
      state.kioskHealthPingCount = state.kioskHealthPingCount + 1;
      state.lastVersionCheck = new Date();
      if (action.payload?.location) {
        state.location = action.payload?.location;
      }

      const nextState = { ...state };

      if (nextState.kioskHealthPingCount % 6 === 0) {
        // Every 30 mins log a metrics event for Kiosk Dashboard Collection
        appInsights.trackEvent(
          { name: `[${nextState?.kioskName}] ${KIOSK_HEALTH_METRICS}` },
          {
            ...nextState,
          },
        );
      } else {
        appInsights.trackEvent(
          { name: `[${nextState?.kioskName}] ${KIOSK_HEALTH_CHECK}` },
          {
            ...nextState,
          },
        );
      }
    },

    kioskLocationPing(state, action) {
      const nextState = { ...state };

      appInsights.trackEvent(
        { name: `[${action?.payload?.username}] ${KIOSK_LOCATION_WARNING}` },
        {
          ...nextState,
        },
      );
    },

    updatePowerBiStatus(state, action) {
      const currentState = { ...state };

      let errors = Array.from(currentState.powerBiErrors);

      state.powerBiLastStatus = action.payload.status;
      if (action.payload.status === PowerBIReportState.LOADED) {
        state.powerBiLoaded = true;
        state.kioskStatus = "[LOADED]";
      }
      if (action.payload.status === PowerBIReportState.RENDERED) {
        state.powerBiRendered = true;
        state.powerBiRenderedCount = state.powerBiRenderedCount + 1;
        state.kioskStatus = "[RENDERING]";
      }
      if (action.payload.status === PowerBIReportState.ERROR) {
        state.kioskStatus = "[POWERBI ERROR]";
        state.powerBiErrorCount = state.powerBiErrorCount + 1;
        if (action.payload.errorEvent) {
          const message =
            action.payload.errorEvent?.detail?.message +
            ": " +
            action.payload.errorEvent?.detail?.detailedMessage;
          errors.push(message);
        }
      }

      state.powerBiErrors = errors;

      const nextState = { ...state };
      // console.log({ name: `KioskStatus` }, nextState);

      if (action.payload.status === PowerBIReportState.ERROR) {
        appInsights.trackException(
          {
            error: new Error(
              `PowerBI Event Error: ${nextState.kioskName} :: ${nextState.selectedSite}`,
            ),
            severityLevel: SeverityLevel.Error,
          },
          {
            ...nextState,
            event: action.payload?.errorEvent,
          },
        );
      }
    },

    updateKioskOnNewURL(state, action) {
      const currentState = { ...state };
      appInsights.trackEvent(
        { name: `[${currentState?.kioskName}] New URL Found` },
        {
          ...currentState,
          kioskStatus: "New URL Found. Navigation to new URL.",
          newURL: action.payload.newURL,
        },
      );
    },

    reloadKioskOnNewVersion(state, action) {
      const currentState = { ...state };
      appInsights.trackException(
        {
          exception: `[${currentState?.kioskName}] New Version Found`,
          severityLevel: SeverityLevel.Warning,
        },
        {
          ...currentState,
          kioskStatus: "New Version Found. Reloading...",
          newVersion: action.payload.version,
        },
      );
      // appInsights.trackEvent(
      //   { name: `[${currentState?.kioskName}] New Version Found` },
      //   {
      //     ...currentState,
      //     kioskStatus: "New Version Found. Reloading...",
      //     newVersion: action.payload.version,
      //   },
      // );
    },

    powerBiNotLoaded(state, action) {
      state.kioskStatus = "PowerBi Loaded or Rendered Event Not Found";
      const currentState = { ...state };
      appInsights.trackException(
        {
          error: new Error(
            `[${currentState.kioskName}] PowerBi not loaded after 2 pings (~10 mins). Refreshing browser`,
          ),
          severityLevel: SeverityLevel.Error,
        },
        {
          ...currentState,
        },
      );
    },

    powerBiErrorsDetected(state, action) {
      state.kioskStatus = "PowerBi Errors Detected on Health Check";
      const currentState = { ...state };
      appInsights.trackException(
        {
          error: new Error(
            `[${currentState.kioskName}] PowerBi errors detected. Refreshing browser`,
          ),
          severityLevel: SeverityLevel.Error,
        },
        {
          ...currentState,
        },
      );
    },

    reloadOnHealthCheck(state, action) {
      state.kioskStatus = "Issue Detected";
      const currentState = { ...state };
      appInsights.trackException(
        {
          error: new Error(
            `[${currentState.kioskName}] Issue detected during kiosk health check. Refreshing browser`,
          ),
          severityLevel: SeverityLevel.Error,
        },
        {
          ...currentState,
        },
      );
    },
  },
});

// Reducer
export default slice.reducer;

export function updateKioskStatus(payload) {
  return async () => {
    try {
      if (payload.hasOwnProperty("newAppVersionFound")) {
        dispatch(slice.actions.reloadKioskOnNewVersion(payload));
      } else if (payload.hasOwnProperty("newURLFound")) {
        dispatch(slice.actions.updateKioskOnNewURL(payload));
      } else if (payload.hasOwnProperty("kioskHealthPing")) {
        dispatch(slice.actions.updateKioskHealthPing(payload));
      } else if (payload.hasOwnProperty("powerBiNotLoaded")) {
        dispatch(slice.actions.powerBiNotLoaded());
      } else if (payload.hasOwnProperty("powerBiErrorsDetected")) {
        dispatch(slice.actions.powerBiErrorsDetected());
      } else if (payload.hasOwnProperty("reloadOnHealthCheck")) {
        dispatch(slice.actions.reloadOnHealthCheck());
      } else if (payload.hasOwnProperty("kioskLocationPing")) {
        dispatch(slice.actions.kioskLocationPing(payload));
      } else {
        dispatch(slice.actions.updateKioskStatus(payload));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handlePowerBiEvent(payload) {
  return async () => {
    try {
      dispatch(slice.actions.updatePowerBiStatus(payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function handlePowerBiEventError(payload) {
  return async () => {
    try {
      dispatch(slice.actions.updatePowerBiStatus(payload));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
