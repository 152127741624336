import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSiteConfiguration,
  resetSiteConfiguration,
} from "store/slices/tool";
import { setLoading } from "store/slices/utils";
import { useTheme } from "@mui/material/styles";
import { CustomizedSearchInput } from "components/Search/CustomizedSearchInput";

function SiteConfiguration() {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { selectedSite } = useSelector((state) => state.site);
  const { siteConfiguration } = useSelector((state) => state.tool);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [listedCategories, setListedCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [searchFilter, setSearchFilter] = useState("");
  const [custOwner, setCustOwner] = useState("ALL");

  const handleCategorySelect = (viewedConfigIndex) => {
    const updatedCategories = listedCategories.map((item, index) => {
      if (index === viewedConfigIndex) {
        // Only one category can be selected at a time
        item.isSelected = true;
        setSelectedCategory(item);
      } else {
        item.isSelected = false;
      }
      return item;
    });
    setListedCategories(updatedCategories);
  };

  const handleSearchFilter = () => {
    let filteredCategories = siteConfiguration.rows;
    if (searchFilter) {
      filteredCategories = filteredCategories.filter((item) => {
        return item.key.toLowerCase().includes(searchFilter.toLowerCase());
      });
    }
    const mappedCategories = mapListedCategories(filteredCategories);

    // This section is to keep the user on the selected
    // category if it's included in the filtered list
    if (selectedCategory.category) {
      let isCategoryFound = false;
      for (let current of mappedCategories) {
        if (current.category === selectedCategory.category) {
          isCategoryFound = true;
          current.isSelected = true;
          setSelectedCategory(current);
          break;
        }
      }
      if (!isCategoryFound) {
        setSelectedCategory({});
      }
    }
    setListedCategories(mappedCategories);
  };

  const getData = async () => {
    dispatch(setLoading(true));
    await dispatch(getSiteConfiguration({ selectedSite, custOwner }));
    dispatch(setLoading(false));
  };

  const sortProperty = (prop) => {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      } else {
        return 0;
      }
    };
  };

  const mapListedCategories = (data) => {
    const subCatMap = new Map();
    data.forEach((item) => {
      if (!subCatMap.has(item.category)) {
        subCatMap.set(item.category, []);
      }
      subCatMap.get(item.category).push(item);
    });

    const subCategories = Array.from(subCatMap, ([subCategory, settings]) => {
      return { subCategory, settings: settings.sort(sortProperty("key")) };
    });

    const catMap = new Map();
    subCategories.forEach((item) => {
      if (!catMap.has(item.subCategory.split(":")[0])) {
        catMap.set(item.subCategory.split(":")[0], []);
      }
      catMap.get(item.subCategory.split(":")[0]).push(item);
    });

    const categories = Array.from(catMap, ([category, subCategories]) => {
      return {
        category,
        subCategories: subCategories.sort(sortProperty("subCategory")),
        isSelected: false,
      };
    });

    categories.sort(sortProperty("category"));
    return categories;
  };

  const formatBooleanValue = (value) => {
    if (typeof value === "boolean") {
      return value ? "Enabled" : "Disabled";
    } else if (value === "Y" || value === "N") {
      return value === "Y" ? "Enabled" : "Disabled";
    } else {
      return value || "N/A";
    }
  };

  const getKeyBgColor = (index) => {
    if (theme.palette.mode === "dark") {
      if (index % 2 === 0) {
        return theme.palette.background.default;
      } else {
        return theme.palette.dark[800];
      }
    } else {
      if (index % 2 === 0) {
        return theme.palette.background.default;
      } else {
        return theme.palette.grey[100];
      }
    }
  };

  const isValueBoolean = (value) => {
    if (typeof value === "boolean") {
      return true;
    } else if (value === "Y" || value === "N") {
      return true;
    } else {
      return false;
    }
  };

  const isValueBooleanEnabled = (value) => {
    if (typeof value === "boolean") {
      return value;
    } else if (value === "Y" || value === "N") {
      return value === "Y";
    } else {
      return false;
    }
  };

  const applyValueStyles = (value) => {
    if (isValueBoolean(value)) {
      return {
        bgcolor: isValueBooleanEnabled(value)
          ? theme.palette.primary.main
          : theme.palette.mode === "dark"
            ? theme.palette.grey[300]
            : theme.palette.grey[200],
        color: isValueBooleanEnabled(value)
          ? "white"
          : theme.palette.text.primary,
      };
    } else {
      return {
        bgcolor: theme.palette.background.paper,
        color: theme.palette.primary.main,
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: theme.palette.primary.main,
      };
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(resetSiteConfiguration());
    };
  }, [custOwner]);

  useEffect(() => {
    setListedCategories(mapListedCategories(siteConfiguration.rows));
  }, [siteConfiguration]);

  useEffect(() => {
    handleSearchFilter();
  }, [searchFilter]);

  useEffect(() => {
    if (isFirstLoad && listedCategories.length) {
      handleCategorySelect(0);
      setIsFirstLoad(false);
    }
  }, [listedCategories]);

  return (
    <Stack gap="10px">
      <Box display="flex" gap="10px" alignItems="center">
        <Typography fontWeight="bold">Customer Owner</Typography>
        <Select
          value={custOwner}
          onChange={(e) => {
            setCustOwner(e.target.value);
          }}
          size="small"
          sx={{
            minWidth: "410px",
            borderRadius: "4px",
          }}
        >
          <MenuItem value="ALL">ALL</MenuItem>
        </Select>
      </Box>
      <Box display="flex" alignItems="flex-start">
        <Box
          sx={{
            width: 250,
            minWidth: 250,
            bgcolor: "background.paper",
            padding: "10px",
            borderRadius: "4px",
            border: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.grey[200],
            display: "flex",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <CustomizedSearchInput
            searchFilter={searchFilter}
            setSearchFilter={setSearchFilter}
          />
          {listedCategories.length ? (
            <List
              sx={{
                padding: 0,
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              {listedCategories.map((item, index) => (
                <ListItemButton
                  key={item.category}
                  selected={item.isSelected}
                  onClick={() => handleCategorySelect(index)}
                  sx={{
                    borderRadius: "4px",
                    marginRight: "2px",
                  }}
                >
                  <ListItemText
                    primary={item.category}
                    sx={{
                      ".MuiTypography-root": {
                        fontWeight: item.isSelected ? "bold" : "normal",
                      },
                      ".MuiListItemText-primary": {
                        color: item.isSelected
                          ? theme.palette.primary.main
                          : theme.palette.text.primary,
                      },
                    }}
                  />
                </ListItemButton>
              ))}
            </List>
          ) : null}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          flexWrap="wrap"
          gap="10px"
          width="100%"
          sx={{ padding: "15px" }}
        >
          {selectedCategory?.subCategories?.map((item) => (
            <Box key={item.subCategory}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  paddingBottom: "10px",
                }}
              >
                {item.subCategory}
              </Typography>
              <List sx={{ padding: "10px 0" }}>
                {item.settings.map((subItem, subIndex) => (
                  <ListItem
                    key={subItem.key}
                    gap="10px"
                    sx={{
                      padding: "15px",
                      bgcolor: getKeyBgColor(subIndex),
                      borderWidth: subIndex === 0 ? "1px" : "0 1px 1px 1px",
                      borderColor: theme.palette.grey[200],
                      borderStyle: "solid",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box display="flex" flexDirection="column" gap={"10px"}>
                      <Box sx={{ fontWeight: "bold", fontSize: "16px" }}>
                        {subItem.key}
                      </Box>
                      <Box
                        sx={{
                          color: theme.palette.text.secondary,
                          fontSize: "14px",
                        }}
                      >
                        {subItem.description}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        padding: "10px",
                        borderColor: theme.palette.primary.main,
                        fontSize: "16px",
                        borderRadius: "100px",
                        ".MuiTypography-root": { fontWeight: "500" },
                        ...applyValueStyles(subItem.value),
                      }}
                    >
                      <Typography>
                        {formatBooleanValue(subItem.value)}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
      </Box>
    </Stack>
  );
}

export default SiteConfiguration;
