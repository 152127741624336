import { UpdateSiteRequestObject } from "../constants/site";

const mapPropertiesToRequestObject = (requestObject, currentObject) => {
  return Object.keys(requestObject).reduce((acc, property) => {
    if (currentObject[property]) {
      if (
        typeof requestObject[property] === "object" &&
        !Array.isArray(requestObject[property])
      ) {
        // Recursively copy nested objects instead of modifying requestObject
        acc[property] = mapPropertiesToRequestObject(
          requestObject[property],
          currentObject[property]
        );
      } else {
        acc[property] = currentObject[property];
      }
    }
    return acc;
  }, { ...requestObject }); // Create a new object to avoid mutating the original
};

export const getSiteWithPropertiesToUpdate = (selectedSite) => {
  return mapPropertiesToRequestObject(UpdateSiteRequestObject, selectedSite);
};
