import { useEffect, useState } from "react";
import MuiSnackbar from "@mui/material/Snackbar";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import { storage } from "../../utils/helpers/storage";

// eslint-disable-next-line no-unused-vars
let activeVersionCheckPoll;

export const SWReloadPopup = ({ show }) => {
  let location = useLocation();

  const [open, setOpen] = useState(false);

  async function fetchVersion() {
    try {
      const response = await fetch("/version.json");
      const json = await response.json();
      return json?.version;
    } catch (error) {
      console.error("Error fetching version:", error);
      return null;
    }
  }

  const checkForNewAppVersion = async (setOpen) => {
    if (!activeVersionCheckPoll) {
      activeVersionCheckPoll = setInterval(async function () {
        const version = await fetchVersion();
        let currentVersion = storage.getLocusAppVersion();
        if (!currentVersion) {
          storage.setLocusAppVersion(version);
        } else if (currentVersion !== version) {
          setOpen(true);
        }
      }, 300000);
    }
  };

  useEffect(() => {
    if (!storage.getKiosk()) {
      checkForNewAppVersion(setOpen);
    }
  }, [location]);

  useEffect(() => {
    if (show) {
      setOpen(true);
    }
  }, [show]);
  return (
    <MuiSnackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      open={open}
      onClose={() => setOpen(false)}
      message="New content is available and will be used when all tabs for this application are closed and the browser is reloaded"
      key="service-worker-reload-popup"
      action={
        <>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      }
    />
  );
};
