import { getLookupValue } from "./lookupReferences";

const round = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const roundUp = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.ceil(value * multiplier) / multiplier;
}

// Add rounding rules configuration
// Todo checl later
const roundingRules = {
  picking: {
    botMetrics: {
      numberOfRobots: {
        function: 'roundUp',
        precision: 0
      }
    },
    systemMetrics: {
      robotToPickerRatio: {
        function: 'round',
        precision: 1,
        format: 'ratio'
      },
      robotToFteRatio: {
        function: 'round',
        precision: 1,
        format: 'ratio'
      },
      areaPerBot: {
        function: 'roundUp',
        precision: 0,
        applyTo: 'denominator'
      }
    }
  },
  putaway: {
    botMetrics: {
      numberOfRobots: {
        function: 'roundUp',
        precision: 0
      }
    },
    systemMetrics: {
      robotToPickerRatio: {
        function: 'round',
        precision: 1,
        format: 'ratio'
      },
      robotToFteRatio: {
        function: 'round',
        precision: 1,
        format: 'ratio'
      },
      areaPerBot: {
        function: 'roundUp',
        precision: 0,
        applyTo: 'denominator'
      }
    }
  },
  concurrent: {
    total: {
      totalNumberOfRobots: {
        function: 'roundUp',
        precision: 0
      }
    },
    systemMetrics: {
      robotToPickerRatio: {
        function: 'round',
        precision: 1,
        format: 'ratio'
      },
      robotToFteRatio: {
        function: 'round',
        precision: 1,
        format: 'ratio'
      },
      areaPerBot: {
        function: 'roundUp',
        precision: 0,
        applyTo: 'denominator'
      }
    }
  }
};

// Helper function to apply rounding based on rules
const applyRounding = (value, rule) => {
  // console.log(value, rule, 'applyRounding');
  if (!rule) return value;

  let result = value;
  if (rule.function === 'round') {
    result = round(value, rule.precision);
  } else if (rule.function === 'roundUp') {
    result = roundUp(value, rule.precision);
  }

  if (rule.format === 'ratio') {
    result = `${result}:1`;
  }

  return result;
};

// Helper function to get rounding rule for a specific metric
const getRoundingRule = (operation, category, metric) => {
  return roundingRules[operation]?.[category]?.[metric];
};

// Maps output calculation IDs to their computation functions and descriptions
const calculationMap = {
  concurrentSummary: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        // Operations metrics
        const productivePickingHours =
          userInput?.productiveHoursPerDayPicking || 0;
        const productivePutawayHours =
          userInput?.productiveHoursPerDayPutaway || 0;

        // Design demand volume calculations
        const pickingOrdersPerHour = productivePickingHours
          ? (userInput?.designVolumes?.ordersPerDay?.picking || 0) /
            productivePickingHours
          : 0;
        const pickingLinesPerHour = productivePickingHours
          ? (userInput?.designVolumes?.linesPerDay?.picking || 0) /
            productivePickingHours
          : 0;
        const pickingUnitsPerHour = productivePickingHours
          ? (userInput?.designVolumes?.unitsPerDay?.picking || 0) /
            productivePickingHours
          : 0;
        const putawayContainersPerHour = productivePutawayHours
          ? (userInput?.designVolumes?.ordersPerDay?.putaway || 0) /
            productivePutawayHours
          : 0;
        const putawayLinesPerHour = productivePutawayHours
          ? (userInput?.designVolumes?.linesPerDay?.putaway || 0) /
            productivePutawayHours
          : 0;
        const putawayUnitsPerHour = productivePutawayHours
          ? (userInput?.designVolumes?.unitsPerDay?.putaway || 0) /
            productivePutawayHours
          : 0;

        // Picking bot metrics
        const numberOfPickingBots = botCalculations?.totalRobots?.picking || 0;
        const robotPickingLph =
          botCalculations?.allRobotLphDesignVol?.picking || 0;
        const robotPickingUph =
          botCalculations?.allRobotUphDesignVol?.picking || 0;
        const pickingMissionMin =
          (botCalculations?.averageMissionTimeMin?.picking || 0) ; // Convert to days

        // Putaway bot metrics
        const numberOfPutawayBots = botCalculations?.totalRobots?.putaway || 0;
        const robotPutawayLph =
          botCalculations?.allRobotLphDesignVol?.putaway || 0;
        const robotPutawayUph =
          botCalculations?.allRobotUphDesignVol?.putaway || 0;
        const putawayMissionMin =
          (botCalculations?.averageMissionTimeMin?.putaway || 0) ; // Convert to days

        // Format mission time as MM:SS
        const formatMissionTime = (minutes) => {
          const mins = Math.floor(minutes);
          const secs = Math.round((minutes - mins) * 60);
          return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        };

        // Total robots with proper rounding
        const totalNumberOfRobots = 
          numberOfPickingBots + numberOfPutawayBots;

        // Staffing calculations
        const numberOfDirectFtes =
          (botCalculations?.designDirectFtes?.picking || 0) +
          (botCalculations?.designDirectFtes?.putaway || 0);
        const numberOfIndirectPickingFtes =
          botCalculations?.designIndirectFtes?.picking || 0;
        const numberOfIndirectPutawayFtes =
          botCalculations?.designIndirectFtes?.putaway || 0;
        const totalFtes =
          numberOfDirectFtes +
          numberOfIndirectPickingFtes +
          numberOfIndirectPutawayFtes;

        // Picking metrics
        const purePickLph = botCalculations?.lph?.picking || 0;
        const purePickUph = botCalculations?.uph?.picking || 0;
        const pickingTotalFteLph =
          botCalculations?.totalDesignFtes?.picking &&
          botCalculations?.unitsPerLine?.picking
            ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.picking ||
                0) /
              (botCalculations.totalDesignFtes.picking *
                botCalculations.unitsPerLine.picking)
            : 0;
        const pickingTotalFteUph = botCalculations?.totalDesignFtes?.picking
          ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.picking ||
              0) / botCalculations.totalDesignFtes.picking
          : 0;

        // Putaway metrics
        const purePutLph = botCalculations?.lph?.putaway || 0;
        const purePutUph = botCalculations?.uph?.putaway || 0;
        const putawayTotalFteLph =
          botCalculations?.totalDesignFtes?.putaway &&
          botCalculations?.unitsPerLine?.putaway
            ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.putaway ||
                0) /
              (botCalculations.totalDesignFtes.putaway *
                botCalculations.unitsPerLine.putaway)
            : 0;
        const putawayTotalFteUph = botCalculations?.totalDesignFtes?.putaway
          ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.putaway ||
              0) / botCalculations.totalDesignFtes.putaway
          : 0;

        // System metrics with proper rounding
        const robotToPickerRatio = numberOfDirectFtes
          ? `${(totalNumberOfRobots / numberOfDirectFtes).toFixed(1)}:1`
          : "0:1";
        const robotToFteRatio = totalFtes
          ? `${(totalNumberOfRobots / totalFtes).toFixed(1)}:1`
          : "0:1";
        const pickArea = userInput?.totalRobotArea || 0;
        const areaPerBot = totalNumberOfRobots
          ? pickArea / totalNumberOfRobots
          : 0;
        const areaPerPicker = numberOfDirectFtes
          ? pickArea / numberOfDirectFtes
          : 0;

        return {
          ops: {
            productivePickingHoursPerDay: `${productivePickingHours.toFixed(1)} hrs`,
            productivePutawayHoursPerDay: `${productivePutawayHours.toFixed(1)} hrs`,
          },
          designDemandVolume: {
            pickingOrdersPerHour: pickingOrdersPerHour.toLocaleString(),
            pickingLinesPerHour: pickingLinesPerHour.toLocaleString(),
            pickingUnitsPerHour: pickingUnitsPerHour.toLocaleString(),
            putawayContainersPerHour: putawayContainersPerHour.toLocaleString(),
            putawayLinesPerHour: putawayLinesPerHour.toLocaleString(),
            putawayUnitsPerHour: putawayUnitsPerHour.toLocaleString(),
          },
          pickingBotMetrics: {
            numberOfPickingBots: numberOfPickingBots.toFixed(1),
            robotPickingLph: robotPickingLph.toFixed(0),
            robotPickingUph: robotPickingUph.toFixed(0),
            avgRobotMissionMin: formatMissionTime(pickingMissionMin),
          },
          putawayBotMetrics: {
            numberOfPutawayBots: numberOfPutawayBots.toFixed(1),
            robotPutawayLph: robotPutawayLph.toFixed(0),
            robotPutawayUph: robotPutawayUph.toFixed(0),
            avgRobotMissionMin: formatMissionTime(putawayMissionMin),
          },
          total: {
            totalNumberOfRobots: totalNumberOfRobots.toString(),
          },
          staffing: {
            numberOfDirectFtes: numberOfDirectFtes.toFixed(1),
            numberOfIndirectPickingFtes: numberOfIndirectPickingFtes.toFixed(1),
            numberOfIndirectPutawayFtes: numberOfIndirectPutawayFtes.toFixed(1),
            totalFtes: totalFtes.toFixed(1),
          },
          pickingMetrics: {
            purePickLph: purePickLph.toFixed(0),
            purePickUph: purePickUph.toFixed(0),
            totalFteLph: pickingTotalFteLph.toFixed(0),
            totalFteUph: pickingTotalFteUph.toFixed(0),
          },
          putawayMetrics: {
            purePutLph: purePutLph.toFixed(0),
            purePutUph: purePutUph.toFixed(0),
            totalFteLph: putawayTotalFteLph.toFixed(0),
            totalFteUph: putawayTotalFteUph.toFixed(0),
          },
          systemMetrics: {
            robotToPickerRatio,
            robotToFteRatio,
            pickArea: pickArea.toLocaleString(),
            areaPerBot: areaPerBot.toFixed(0),
            areaPerPicker: areaPerPicker.toFixed(0),
          },
        };
      } catch (error) {
        // console.log("Error in concurrentSummary calculation:", error);
        return {
          ops: {
            productivePickingHoursPerDay: "0.0 hrs",
            productivePutawayHoursPerDay: "0.0 hrs",
          },
          designDemandVolume: {
            pickingOrdersPerHour: "0",
            pickingLinesPerHour: "0",
            pickingUnitsPerHour: "0",
            putawayContainersPerHour: "0",
            putawayLinesPerHour: "0",
            putawayUnitsPerHour: "0",
          },
          pickingBotMetrics: {
            numberOfPickingBots: "0.0",
            robotPickingLph: "0",
            robotPickingUph: "0",
            avgRobotMissionMin: "00:00",
          },
          putawayBotMetrics: {
            numberOfPutawayBots: "0.0",
            robotPutawayLph: "0",
            robotPutawayUph: "0",
            avgRobotMissionMin: "00:00",
          },
          total: {
            totalNumberOfRobots: "0",
          },
          staffing: {
            numberOfDirectFtes: "0.0",
            numberOfIndirectPickingFtes: "0.0",
            numberOfIndirectPutawayFtes: "0.0",
            totalFtes: "0.0",
          },
          pickingMetrics: {
            purePickLph: "0",
            purePickUph: "0",
            totalFteLph: "0",
            totalFteUph: "0",
          },
          putawayMetrics: {
            purePutLph: "0",
            purePutUph: "0",
            totalFteLph: "0",
            totalFteUph: "0",
          },
          systemMetrics: {
            robotToPickerRatio: "0:1",
            robotToFteRatio: "0:1",
            pickArea: "0",
            areaPerBot: "0",
            areaPerPicker: "0",
          },
        };
      }
    },
    description:
      "Calculates comprehensive concurrent picking and putaway summary metrics",
    calculation: `concurrentSummary = {
      ops: {
        productivePickingHoursPerDay: from userInput
        productivePutawayHoursPerDay: from userInput
      },
      designDemandVolume: {
        pickingOrdersPerHour: ordersPerDay / productivePickingHours
        pickingLinesPerHour: linesPerDay / productivePickingHours
        pickingUnitsPerHour: unitsPerDay / productivePickingHours
        putawayContainersPerHour: containersPerDay / productivePutawayHours
        putawayLinesPerHour: linesPerDay / productivePutawayHours
        putawayUnitsPerHour: unitsPerDay / productivePutawayHours
      },
      pickingBotMetrics: {
        numberOfPickingBots: from botCalculations
        robotPickingLph: from botCalculations
        robotPickingUph: from botCalculations
        avgRobotMissionMin: averageMissionTimeMin / 1440
      },
      putawayBotMetrics: {
        numberOfPutawayBots: from botCalculations
        robotPutawayLph: from botCalculations
        robotPutawayUph: from botCalculations
        avgRobotMissionMin: averageMissionTimeMin / 1440
      },
      total: {
        totalNumberOfRobots: CEIL(numberOfPickingBots + numberOfPutawayBots)
      },
      staffing: {
        numberOfDirectFtes: sum of picking and putaway direct FTEs
        numberOfIndirectPickingFtes: from botCalculations
        numberOfIndirectPutawayFtes: from botCalculations
        totalFtes: sum of all FTEs
      },
      pickingMetrics: {
        purePickLph: from botCalculations
        purePickUph: from botCalculations
        totalFteLph: designVolumeWithFactorUnitsPerHour / (totalFtes × unitsPerLine)
        totalFteUph: designVolumeWithFactorUnitsPerHour / totalFtes
      },
      putawayMetrics: {
        purePutLph: from botCalculations
        purePutUph: from botCalculations
        totalFteLph: designVolumeWithFactorUnitsPerHour / (totalFtes × unitsPerLine)
        totalFteUph: designVolumeWithFactorUnitsPerHour / totalFtes
      },
      systemMetrics: {
        robotToPickerRatio: totalNumberOfRobots / numberOfDirectFtes
        robotToFteRatio: totalNumberOfRobots / totalFtes
        pickArea: from userInput
        areaPerBot: pickArea / totalNumberOfRobots
        areaPerPicker: pickArea / numberOfDirectFtes
      }
    }`,
    dependencies: [
      "productiveHoursPerDayPicking",
      "productiveHoursPerDayPutaway",
      "designVolumes.ordersPerDay.picking",
      "designVolumes.linesPerDay.picking",
      "designVolumes.unitsPerDay.picking",
      "designVolumes.ordersPerDay.putaway",
      "designVolumes.linesPerDay.putaway",
      "designVolumes.unitsPerDay.putaway",
      "totalRobots.picking",
      "totalRobots.putaway",
      "allRobotLphDesignVol.picking",
      "allRobotLphDesignVol.putaway",
      "allRobotUphDesignVol.picking",
      "allRobotUphDesignVol.putaway",
      "averageMissionTimeMin.picking",
      "averageMissionTimeMin.putaway",
      "designDirectFtes.picking",
      "designDirectFtes.putaway",
      "designIndirectFtes.picking",
      "designIndirectFtes.putaway",
      "totalDesignFtes.picking",
      "totalDesignFtes.putaway",
      "lph.picking",
      "lph.putaway",
      "uph.picking",
      "uph.putaway",
      "designVolumeWithFactorUnitsPerHour.picking",
      "designVolumeWithFactorUnitsPerHour.putaway",
      "unitsPerLine.picking",
      "unitsPerLine.putaway",
      "totalRobotArea",
    ],
  },
  laborUtilization: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        // Time available calculations
        const productiveHoursPicking =
          userInput?.productiveHoursPerDayPicking || 0;
        const productiveHoursPutaway =
          userInput?.productiveHoursPerDayPutaway || 0;
        const productiveMinutesPicking = productiveHoursPicking * 60;
        const productiveMinutesPutaway = productiveHoursPutaway * 60;

        // Labor counts
        const pickingDirectFtes =
          botCalculations?.designDirectFtes?.picking || 0;
        const putawayDirectFtes =
          botCalculations?.designDirectFtes?.putaway || 0;
        const pickingIndirectFtes =
          botCalculations?.designIndirectFtes?.picking || 0;
        const putawayIndirectFtes =
          botCalculations?.designIndirectFtes?.putaway || 0;
        const totalPickingFtes = pickingDirectFtes + pickingIndirectFtes;
        const totalPutawayFtes = putawayDirectFtes + putawayIndirectFtes;
        const totalFtes = totalPickingFtes + totalPutawayFtes;

        // Volume calculations
        const pickingLinesPerDay =
          userInput?.designVolumes?.linesPerDay?.picking || 0;
        const putawayLinesPerDay =
          userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const pickingUnitsPerDay =
          userInput?.designVolumes?.unitsPerDay?.picking || 0;
        const putawayUnitsPerDay =
          userInput?.designVolumes?.unitsPerDay?.putaway || 0;

        // Productivity calculations
        const pickingLph = productiveHoursPicking
          ? pickingLinesPerDay / productiveHoursPicking
          : 0;
        const putawayLph = productiveHoursPutaway
          ? putawayLinesPerDay / productiveHoursPutaway
          : 0;
        const pickingUph = productiveHoursPicking
          ? pickingUnitsPerDay / productiveHoursPicking
          : 0;
        const putawayUph = productiveHoursPutaway
          ? putawayUnitsPerDay / productiveHoursPutaway
          : 0;

        // Per FTE productivity
        const pickingLphPerFte = totalPickingFtes
          ? pickingLph / totalPickingFtes
          : 0;
        const putawayLphPerFte = totalPutawayFtes
          ? putawayLph / totalPutawayFtes
          : 0;
        const pickingUphPerFte = totalPickingFtes
          ? pickingUph / totalPickingFtes
          : 0;
        const putawayUphPerFte = totalPutawayFtes
          ? putawayUph / totalPutawayFtes
          : 0;

        // Direct labor utilization
        const pickingDirectUtilization =
          botCalculations?.directLaborUtilization?.picking || 0;
        const putawayDirectUtilization =
          botCalculations?.directLaborUtilization?.putaway || 0;
        const overallDirectUtilization =
          pickingDirectFtes + putawayDirectFtes
            ? (pickingDirectUtilization * pickingDirectFtes +
                putawayDirectUtilization * putawayDirectFtes) /
              (pickingDirectFtes + putawayDirectFtes)
            : 0;

        // Indirect labor utilization
        const pickingIndirectUtilization =
          botCalculations?.indirectLaborUtilization?.picking || 0;
        const putawayIndirectUtilization =
          botCalculations?.indirectLaborUtilization?.putaway || 0;
        const overallIndirectUtilization =
          pickingIndirectFtes + putawayIndirectFtes
            ? (pickingIndirectUtilization * pickingIndirectFtes +
                putawayIndirectUtilization * putawayIndirectFtes) /
              (pickingIndirectFtes + putawayIndirectFtes)
            : 0;

        // Overall utilization
        const pickingOverallUtilization = totalPickingFtes
          ? (pickingDirectUtilization * pickingDirectFtes +
              pickingIndirectUtilization * pickingIndirectFtes) /
            totalPickingFtes
          : 0;
        const putawayOverallUtilization = totalPutawayFtes
          ? (putawayDirectUtilization * putawayDirectFtes +
              putawayIndirectUtilization * putawayIndirectFtes) /
            totalPutawayFtes
          : 0;
        const totalOverallUtilization = totalFtes
          ? (pickingOverallUtilization * totalPickingFtes +
              putawayOverallUtilization * totalPutawayFtes) /
            totalFtes
          : 0;

        return {
          laborCounts: {
            pickingDirectFtes: pickingDirectFtes.toFixed(1),
            putawayDirectFtes: putawayDirectFtes.toFixed(1),
            pickingIndirectFtes: pickingIndirectFtes.toFixed(1),
            putawayIndirectFtes: putawayIndirectFtes.toFixed(1),
            totalPickingFtes: totalPickingFtes.toFixed(1),
            totalPutawayFtes: totalPutawayFtes.toFixed(1),
            totalFtes: totalFtes.toFixed(1),
          },
          timeAvailable: {
            productiveHoursPicking: `${productiveHoursPicking.toFixed(1)} hrs`,
            productiveHoursPutaway: `${productiveHoursPutaway.toFixed(1)} hrs`,
            productiveMinutesPicking: `${productiveMinutesPicking.toFixed(0)} min`,
            productiveMinutesPutaway: `${productiveMinutesPutaway.toFixed(0)} min`,
          },
          productivity: {
            pickingLph: pickingLph.toFixed(0),
            putawayLph: putawayLph.toFixed(0),
            pickingUph: pickingUph.toFixed(0),
            putawayUph: putawayUph.toFixed(0),
            pickingLphPerFte: pickingLphPerFte.toFixed(0),
            putawayLphPerFte: putawayLphPerFte.toFixed(0),
            pickingUphPerFte: pickingUphPerFte.toFixed(0),
            putawayUphPerFte: putawayUphPerFte.toFixed(0),
          },
          directUtilization: {
            picking: `${pickingDirectUtilization.toFixed(1)}%`,
            putaway: `${putawayDirectUtilization.toFixed(1)}%`,
            overall: `${overallDirectUtilization.toFixed(1)}%`,
          },
          indirectUtilization: {
            picking: `${pickingIndirectUtilization.toFixed(1)}%`,
            putaway: `${putawayIndirectUtilization.toFixed(1)}%`,
            overall: `${overallIndirectUtilization.toFixed(1)}%`,
          },
          overallUtilization: {
            picking: `${pickingOverallUtilization.toFixed(1)}%`,
            putaway: `${putawayOverallUtilization.toFixed(1)}%`,
            total: `${totalOverallUtilization.toFixed(1)}%`,
          },
          rawValues: {
            pickingDirectUtilizationRaw: pickingDirectUtilization,
            putawayDirectUtilizationRaw: putawayDirectUtilization,
            pickingIndirectUtilizationRaw: pickingIndirectUtilization,
            putawayIndirectUtilizationRaw: putawayIndirectUtilization,
            pickingOverallUtilizationRaw: pickingOverallUtilization,
            putawayOverallUtilizationRaw: putawayOverallUtilization,
            totalOverallUtilizationRaw: totalOverallUtilization,
          },
        };
      } catch (error) {
        // console.log("Error in laborUtilization calculation:", error);
        return {
          laborCounts: {
            pickingDirectFtes: "0.0",
            putawayDirectFtes: "0.0",
            pickingIndirectFtes: "0.0",
            putawayIndirectFtes: "0.0",
            totalPickingFtes: "0.0",
            totalPutawayFtes: "0.0",
            totalFtes: "0.0",
          },
          timeAvailable: {
            productiveHoursPicking: "0.0 hrs",
            productiveHoursPutaway: "0.0 hrs",
            productiveMinutesPicking: "0 min",
            productiveMinutesPutaway: "0 min",
          },
          productivity: {
            pickingLph: "0",
            putawayLph: "0",
            pickingUph: "0",
            putawayUph: "0",
            pickingLphPerFte: "0",
            putawayLphPerFte: "0",
            pickingUphPerFte: "0",
            putawayUphPerFte: "0",
          },
          directUtilization: {
            picking: "0.0%",
            putaway: "0.0%",
            overall: "0.0%",
          },
          indirectUtilization: {
            picking: "0.0%",
            putaway: "0.0%",
            overall: "0.0%",
          },
          overallUtilization: {
            picking: "0.0%",
            putaway: "0.0%",
            total: "0.0%",
          },
          rawValues: {
            pickingDirectUtilizationRaw: 0,
            putawayDirectUtilizationRaw: 0,
            pickingIndirectUtilizationRaw: 0,
            putawayIndirectUtilizationRaw: 0,
            pickingOverallUtilizationRaw: 0,
            putawayOverallUtilizationRaw: 0,
            totalOverallUtilizationRaw: 0,
          },
        };
      }
    },
    description:
      "Calculates detailed labor utilization metrics for both picking and putaway operations",
    calculation: `laborUtilization = {
      laborCounts: {
        pickingDirectFtes: from botCalculations
        putawayDirectFtes: from botCalculations
        pickingIndirectFtes: from botCalculations
        putawayIndirectFtes: from botCalculations
        totalPickingFtes: pickingDirectFtes + pickingIndirectFtes
        totalPutawayFtes: putawayDirectFtes + putawayIndirectFtes
        totalFtes: totalPickingFtes + totalPutawayFtes
      },
      timeAvailable: {
        productiveHoursPicking: from userInput
        productiveHoursPutaway: from userInput
        productiveMinutesPicking: productiveHoursPicking * 60
        productiveMinutesPutaway: productiveHoursPutaway * 60
      },
      productivity: {
        pickingLph: pickingLinesPerDay / productiveHoursPicking
        putawayLph: putawayLinesPerDay / productiveHoursPutaway
        pickingUph: pickingUnitsPerDay / productiveHoursPicking
        putawayUph: putawayUnitsPerDay / productiveHoursPutaway
        pickingLphPerFte: pickingLph / totalPickingFtes
        putawayLphPerFte: putawayLph / totalPutawayFtes
        pickingUphPerFte: pickingUph / totalPickingFtes
        putawayUphPerFte: putawayUph / totalPutawayFtes
      },
      directUtilization: weighted average of picking and putaway direct labor utilization
      indirectUtilization: weighted average of picking and putaway indirect labor utilization
      overallUtilization: weighted average of all labor utilization
    }`,
    dependencies: [
      "productiveHoursPerDayPicking",
      "productiveHoursPerDayPutaway",
      "designVolumes.linesPerDay.picking",
      "designVolumes.linesPerDay.putaway",
      "designVolumes.unitsPerDay.picking",
      "designVolumes.unitsPerDay.putaway",
      "designDirectFtes.picking",
      "designDirectFtes.putaway",
      "designIndirectFtes.picking",
      "designIndirectFtes.putaway",
      "directLaborUtilization.picking",
      "directLaborUtilization.putaway",
      "indirectLaborUtilization.picking",
      "indirectLaborUtilization.putaway",
    ],
  },
  pickingBotMissionWorkflow: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      return {
        workflow: {
          inductTote: {
            distance: null,
            time: botCalculations.inductToteTime?.picking || 50.0,
            multiplier: 1,
            totalTime: (botCalculations.inductToteTime?.picking || 50.0) * 1,
          },
          travelToFirstPick: {
            distance: botCalculations.firstPickDistance?.picking || 220.0,
            time: botCalculations.firstPickTime?.picking || 73.3,
            multiplier: 1,
            totalTime: (botCalculations.firstPickTime?.picking || 73.3) * 1,
          },
          waitForPicker: {
            distance: null,
            time: botCalculations.waitForPickerTime?.picking || 45.0,
            multiplier: 1,
            totalTime: (botCalculations.waitForPickerTime?.picking || 45.0) * 1,
          },
          pickLineItems: {
            distance: null,
            time: botCalculations.pickLineItemsTime?.picking || 14.0,
            multiplier: 1,
            totalTime: (botCalculations.pickLineItemsTime?.picking || 14.0) * 1,
          },
          travelToNextPick: {
            distance: botCalculations.nextPickDistance?.picking || 105.7,
            time: botCalculations.nextPickTime?.picking || 35.2,
            multiplier: botCalculations.nextPickMultiplier?.picking || 7,
            totalTime:
              (botCalculations.nextPickTime?.picking || 35.2) *
              (botCalculations.nextPickMultiplier?.picking || 7),
          },
          secondWaitForPicker: {
            distance: null,
            time: botCalculations.waitForPickerTime?.picking || 45.0,
            multiplier: botCalculations.nextPickMultiplier?.picking || 7,
            totalTime:
              (botCalculations.waitForPickerTime?.picking || 45.0) *
              (botCalculations.nextPickMultiplier?.picking || 7),
          },
          secondPickLineItems: {
            distance: null,
            time: botCalculations.pickLineItemsTime?.picking || 14.0,
            multiplier: botCalculations.nextPickMultiplier?.picking || 7,
            totalTime:
              (botCalculations.pickLineItemsTime?.picking || 14.0) *
              (botCalculations.nextPickMultiplier?.picking || 7),
          },
          travelToDropoff: {
            distance: botCalculations.dropoffDistance?.picking || 220.0,
            time: botCalculations.dropoffTime?.picking || 73.3,
            multiplier: 1,
            totalTime: (botCalculations.dropoffTime?.picking || 73.3) * 1,
          },
          dropoffQueue: {
            distance: null,
            time: botCalculations.queueTime?.picking || 60.0,
            multiplier: 1,
            totalTime: (botCalculations.queueTime?.picking || 60.0) * 1,
          },
          removeTote: {
            distance: null,
            time: botCalculations.removeToteTime?.picking || 20.0,
            multiplier: 1,
            totalTime: (botCalculations.removeToteTime?.picking || 20.0) * 1,
          },
        },
        summaryMetrics: {
          avgActivePickingTime:
            botCalculations.avgActivePickingTime?.picking || 67.7,
          totalJobsPerHourRequired:
            botCalculations.totalJobsPerHourRequired?.picking || 312.5,
          avgRobotMissionDistance:
            botCalculations.avgRobotMissionDistance?.picking || 1179.6,
          avgJobTimeMin:
            botCalculations.averageMissionTimeMin?.picking || 16.59,
          robotJobsPerHour: botCalculations.robotJobsPerHour?.picking || 3.62,
          avgAvailableTime: botCalculations.avgAvailableTime?.picking || 68.3,
        },
      };
    },
    description:
      "Calculates the detailed workflow timing for picking bot missions",
    dependencies: [
      "inductToteTime.picking",
      "firstPickDistance.picking",
      "firstPickTime.picking",
      "waitForPickerTime.picking",
      "pickLineItemsTime.picking",
      "nextPickDistance.picking",
      "nextPickTime.picking",
      "nextPickMultiplier.picking",
      "dropoffDistance.picking",
      "dropoffTime.picking",
      "queueTime.picking",
      "removeToteTime.picking",
      "avgActivePickingTime.picking",
      "totalJobsPerHourRequired.picking",
      "avgRobotMissionDistance.picking",
      "averageMissionTimeMin.picking",
      "robotJobsPerHour.picking",
      "avgAvailableTime.picking",
    ],
  },
  pickingOnlySummary: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        // console.log("Computing pickingOnlySummary with:", {
        //   userInput,
        //   staticInputs,
        //   lookupReference,
        //   botCalculations,
        // });

        // Operations metrics
        const productiveHours = userInput?.productiveHoursPerDayPicking || 0;

        // Design demand volume calculations
        const ordersPerDay =
          userInput?.designVolumes?.ordersPerDay?.picking || 0;
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.picking || 0;
        const unitsPerDay = userInput?.designVolumes?.unitsPerDay?.picking || 0;
        const ordersPerHour = productiveHours
          ? ordersPerDay / productiveHours
          : 0;
        const linesPerHour = productiveHours
          ? linesPerDay / productiveHours
          : 0;
        const unitsPerHour = productiveHours
          ? unitsPerDay / productiveHours
          : 0;

        // Bot metrics - following Excel formulas exactly
        // C143 = totalRobots = C140 + C142 (activeRobots + chargingRobots)
        const totalRobots = (
          (botCalculations?.activeRobots?.picking || 0) +
          (botCalculations?.chargingRobots?.picking || 0)
        );
        // console.log("totalRobots 999", totalRobots, botCalculations);

        // C147 = designLinesPerHour = C33/C21 (linesPerDay/productiveHours)
        const designLinesPerHour = productiveHours
          ? linesPerDay / productiveHours
          : 0;

        // C148 = allRobotLphDesignVol = C147/C143 (designLinesPerHour/totalRobots)
        const robotLph = totalRobots ? designLinesPerHour / totalRobots : 0;

        // C149 = allRobotUphDesignVol = C148*C157
        const unitsPerLine = botCalculations?.unitsPerLine?.picking || 0;
        const robotUph = robotLph * unitsPerLine;

        const numberOfRobots = (totalRobots);
        const avgRobotMissionMin =
          (botCalculations?.averageMissionTimeMin?.picking || 0); // Convert to days
        // console.log("avgRobotMissionMin", avgRobotMissionMin);
        // Format mission time as MM:SS
        const formatMissionTime = (minutes) => {
          const mins = Math.floor(minutes);
          const secs = Math.round((minutes - mins) * 60);
          return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        };

        // Picking metrics
        const numberOfPickers = botCalculations?.designDirectFtes?.picking || 0;
        const inductDropoffFtes =
          botCalculations?.designIndirectFtes?.picking || 0;
        const totalFtes = botCalculations?.totalDesignFtes?.picking || 0;
        const purePickLph = botCalculations?.lph?.picking || 0;
        // console.log("botCalculations 7777", botCalculations);
        const purePickUph = botCalculations?.uph?.picking || 0;
        const totalFteLph =
          totalFtes && botCalculations?.unitsPerLine?.picking
            ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.picking ||
                0) /
              (totalFtes * botCalculations.unitsPerLine.picking)
            : 0;
        const totalFteUph = totalFtes
          ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.picking ||
              0) / totalFtes
          : 0;

        // System metrics with proper rounding
        const robotToPickerRatio = numberOfPickers
          ? `${(numberOfRobots / numberOfPickers).toFixed(1)}:1`
          : "0:1";
        const robotToFteRatio = totalFtes
          ? `${(numberOfRobots / totalFtes).toFixed(1)}:1`
          : "0:1";
        const pickArea = userInput?.totalRobotArea || 0;
        const areaPerBot = numberOfRobots
          ? pickArea / numberOfRobots
          : 0;
        const areaPerPicker = numberOfPickers ? pickArea / numberOfPickers : 0;

        // });

        return {
          ops: {
            productiveHoursPerDay: `${productiveHours.toFixed(1)} hrs`,
          },
          designDemandVolume: {
            ordersPerDay: ordersPerDay.toLocaleString(),
            linesPerDay: linesPerDay.toLocaleString(),
            unitsPerDay: unitsPerDay.toLocaleString(),
            ordersPerHour: ordersPerHour.toLocaleString(),
            linesPerHour: linesPerHour.toLocaleString(),
            unitsPerHour: unitsPerHour.toLocaleString(),
          },
          botMetrics: {
            numberOfRobots: numberOfRobots.toString(),
            robotLph: robotLph.toFixed(2),
            robotUph: robotUph.toFixed(2),
            avgRobotMissionMin: formatMissionTime(avgRobotMissionMin),
          },
          pickingMetrics: {
            numberOfPickers: numberOfPickers.toFixed(1),
            inductDropoffFtes: inductDropoffFtes.toFixed(1),
            totalFtes: totalFtes.toFixed(1),
            purePickLph: purePickLph.toFixed(2),
            purePickUph: purePickUph.toFixed(2),
            totalFteLph: totalFteLph.toFixed(2),
            totalFteUph: totalFteUph.toFixed(2),
          },
          systemMetrics: {
            robotToPickerRatio,
            robotToFteRatio,
            pickArea: pickArea.toLocaleString(),
            areaPerBot: areaPerBot.toFixed(2),
            areaPerPicker: areaPerPicker.toFixed(2),
          },
        };
      } catch (error) {
        // console.log("Error in pickingOnlySummary calculation:", error);
        return {
          ops: {
            productiveHoursPerDay: "0.0 hrs",
          },
          designDemandVolume: {
            ordersPerDay: "0",
            linesPerDay: "0",
            unitsPerDay: "0",
            ordersPerHour: "0",
            linesPerHour: "0",
            unitsPerHour: "0",
          },
          botMetrics: {
            numberOfRobots: "0",
            robotLph: "0.00",
            robotUph: "0.00",
            avgRobotMissionMin: "00:00",
          },
          pickingMetrics: {
            numberOfPickers: "0.0",
            inductDropoffFtes: "0.0",
            totalFtes: "0.0",
            purePickLph: "0",
            purePickUph: "0",
            totalFteLph: "0",
            totalFteUph: "0",
          },
          systemMetrics: {
            robotToPickerRatio: "0:1",
            robotToFteRatio: "0:1",
            pickArea: "0",
            areaPerBot: "0",
            areaPerPicker: "0",
          },
        };
      }
    },
    description: "Calculates comprehensive picking-only summary metrics",
    calculation: `pickingOnlySummary = {
      ops: {
        productiveHoursPerDay: from userInput.productiveHoursPerDayPicking
      },
      designDemandVolume: {
        ordersPerDay: from userInput
        linesPerDay: from userInput
        unitsPerDay: from userInput
        ordersPerHour: ordersPerDay / productiveHours
        linesPerHour: linesPerDay / productiveHours
        unitsPerHour: unitsPerDay / productiveHours
      },
      botMetrics: {
        numberOfRobots: CEIL(totalRobots)
        robotLph: from botCalculations
        robotUph: from botCalculations
        avgRobotMissionMin: averageMissionTimeMin / 1440
      },
      pickingMetrics: {
        numberOfPickers: from botCalculations
        inductDropoffFtes: from botCalculations
        totalFtes: from botCalculations
        purePickLph: from botCalculations
        purePickUph: from botCalculations
        totalFteLph: designVolumeWithFactorUnitsPerHour / (totalFtes × unitsPerLine)
        totalFteUph: designVolumeWithFactorUnitsPerHour / totalFtes
      },
      systemMetrics: {
        robotToPickerRatio: numberOfRobots / numberOfPickers
        robotToFteRatio: numberOfRobots / totalFtes
        pickArea: from userInput
        areaPerBot: pickArea / numberOfRobots
        areaPerPicker: pickArea / numberOfPickers
      }
    }`,
    dependencies: [
      "productiveHoursPerDayPicking",
      "designVolumes.ordersPerDay.picking",
      "designVolumes.linesPerDay.picking",
      "designVolumes.unitsPerDay.picking",
      "totalRobots.picking",
      "allRobotLphDesignVol.picking",
      "allRobotUphDesignVol.picking",
      "averageMissionTimeMin.picking",
      "designDirectFtes.picking",
      "designIndirectFtes.picking",
      "totalDesignFtes.picking",
      "lph.picking",
      "uph.picking",
      "designVolumeWithFactorUnitsPerHour.picking",
      "unitsPerLine.picking",
      "totalRobotArea",
    ],
  },
  putawayBotMissionWorkflow: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      return {
        workflow: {
          inductTote: {
            distance: null,
            time: botCalculations.inductToteTime?.putaway || 30.0,
            multiplier: 1,
            totalTime: (botCalculations.inductToteTime?.putaway || 30.0) * 1,
          },
          travelToFirstPick: {
            distance: botCalculations.firstPickDistance?.putaway || 73.3,
            time: botCalculations.firstPickTime?.putaway || 73.3,
            multiplier: 1,
            totalTime: (botCalculations.firstPickTime?.putaway || 73.3) * 1,
          },
          waitForPicker: {
            distance: null,
            time: botCalculations.waitForPickerTime?.putaway || 45.0,
            multiplier: 1,
            totalTime: (botCalculations.waitForPickerTime?.putaway || 45.0) * 1,
          },
          pickLineItems: {
            distance: null,
            time: botCalculations.pickLineItemsTime?.putaway || 20.0,
            multiplier: 1,
            totalTime: (botCalculations.pickLineItemsTime?.putaway || 20.0) * 1,
          },
          travelToNextPick: {
            distance: botCalculations.nextPickDistance?.putaway || 115.5,
            time: botCalculations.nextPickTime?.putaway || 38.5,
            multiplier: botCalculations.nextPickMultiplier?.putaway || 3,
            totalTime:
              (botCalculations.nextPickTime?.putaway || 38.5) *
              (botCalculations.nextPickMultiplier?.putaway || 3),
          },
          secondWaitForPicker: {
            distance: null,
            time: botCalculations.waitForPickerTime?.putaway || 45.0,
            multiplier: botCalculations.nextPickMultiplier?.putaway || 3,
            totalTime:
              (botCalculations.waitForPickerTime?.putaway || 45.0) *
              (botCalculations.nextPickMultiplier?.putaway || 3),
          },
          secondPickLineItems: {
            distance: null,
            time: botCalculations.pickLineItemsTime?.putaway || 20.0,
            multiplier: botCalculations.nextPickMultiplier?.putaway || 3,
            totalTime:
              (botCalculations.pickLineItemsTime?.putaway || 20.0) *
              (botCalculations.nextPickMultiplier?.putaway || 3),
          },
          travelToDropoff: {
            distance: botCalculations.dropoffDistance?.putaway || 220.0,
            time: botCalculations.dropoffTime?.putaway || 73.3,
            multiplier: 1,
            totalTime: (botCalculations.dropoffTime?.putaway || 73.3) * 1,
          },
          dropoffQueue: {
            distance: null,
            time: botCalculations.queueTime?.putaway || 60.0,
            multiplier: 1,
            totalTime: (botCalculations.queueTime?.putaway || 60.0) * 1,
          },
          removeTote: {
            distance: null,
            time: botCalculations.removeToteTime?.putaway || 10.0,
            multiplier: 1,
            totalTime: (botCalculations.removeToteTime?.putaway || 10.0) * 1,
          },
        },
        summaryMetrics: {
          avgActivePickingTime: `${botCalculations.avgActivePickingTime?.putaway || 53.1}%`,
          totalJobsPerHourRequired: `${botCalculations.totalJobsPerHourRequired?.putaway || 62.5}`,
          avgRobotMissionDistance: `${botCalculations.avgRobotMissionDistance?.putaway || 786.5}`,
          avgJobTimeMin: `${botCalculations.averageMissionTimeMin?.putaway || 10.37}`,
          robotJobsPerHour: `${botCalculations.robotJobsPerHour?.putaway || 5.79}`,
          avgAvailableTime: `${botCalculations.avgAvailableTime?.putaway || 59.3}%`,
        },
      };
    },
    description:
      "Calculates the detailed workflow timing for putaway bot missions",
    dependencies: [
      "inductToteTime.putaway",
      "firstPickDistance.putaway",
      "firstPickTime.putaway",
      "waitForPickerTime.putaway",
      "pickLineItemsTime.putaway",
      "nextPickDistance.putaway",
      "nextPickTime.putaway",
      "nextPickMultiplier.putaway",
      "dropoffDistance.putaway",
      "dropoffTime.putaway",
      "queueTime.putaway",
      "removeToteTime.putaway",
      "avgActivePickingTime.putaway",
      "totalJobsPerHourRequired.putaway",
      "avgRobotMissionDistance.putaway",
      "averageMissionTimeMin.putaway",
      "robotJobsPerHour.putaway",
      "avgAvailableTime.putaway",
    ],
  },
  putawayOnlySummary: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        // Operations metrics
        const productiveHours = userInput?.productiveHoursPerDayPutaway || 0;

        // Design demand volume calculations
        const containersPerDay =
          userInput?.designVolumes?.ordersPerDay?.putaway || 0;
        const linesPerDay = userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const unitsPerDay = userInput?.designVolumes?.unitsPerDay?.putaway || 0;
        const containersPerHour = productiveHours
          ? containersPerDay / productiveHours
          : 0;
        const linesPerHour = productiveHours
          ? linesPerDay / productiveHours
          : 0;
        const unitsPerHour = productiveHours
          ? unitsPerDay / productiveHours
          : 0;

        // Bot metrics
        const numberOfRobots = Math.ceil(
          botCalculations?.totalRobots?.putaway || 0,
        );
        const robotLph = botCalculations?.allRobotLphDesignVol?.putaway || 0;
        const robotUph = botCalculations?.allRobotUphDesignVol?.putaway || 0;
        const avgRobotMissionMin =
          (botCalculations?.averageMissionTimeMin?.putaway || 0); // Convert to days

        // Format mission time as MM:SS
        const formatMissionTime = (minutes) => {
          const mins = Math.floor(minutes);
          const secs = Math.round((minutes - mins) * 60);
          return `${String(mins).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
        };

        // Putaway metrics
        const numberOfPickers = botCalculations?.designDirectFtes?.putaway || 0;
        const inductDropoffFtes =
          botCalculations?.designIndirectFtes?.putaway || 0;
        const totalFtes = botCalculations?.totalDesignFtes?.putaway || 0;
        const purePutLph = botCalculations?.lph?.putaway || 0;
        const purePutUph = botCalculations?.uph?.putaway || 0;
        const totalFteLph =
          totalFtes && botCalculations?.unitsPerLine?.putaway
            ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.putaway ||
                0) /
              (totalFtes * botCalculations.unitsPerLine.putaway)
            : 0;
        const totalFteUph = totalFtes
          ? (botCalculations?.designVolumeWithFactorUnitsPerHour?.putaway ||
              0) / totalFtes
          : 0;

        // System metrics with proper rounding
        const robotToPickerRatio = numberOfPickers
          ? `${(numberOfRobots / numberOfPickers).toFixed(1)}:1`
          : "0:1";
        const robotToFteRatio = totalFtes
          ? `${(numberOfRobots / totalFtes).toFixed(1)}:1`
          : "0:1";
        const pickArea = userInput?.totalRobotArea || 0;
        const areaPerBot = numberOfRobots
          ? pickArea / numberOfRobots
          : 0;
        const areaPerPicker = numberOfPickers ? pickArea / numberOfPickers : 0;

        return {
          ops: {
            productiveHoursPerDay: `${productiveHours.toFixed(1)} hrs`,
          },
          designDemandVolume: {
            containersPerDay: containersPerDay.toLocaleString(),
            linesPerDay: linesPerDay.toLocaleString(),
            unitsPerDay: unitsPerDay.toLocaleString(),
            containersPerHour: containersPerHour.toLocaleString(),
            linesPerHour: linesPerHour.toLocaleString(),
            unitsPerHour: unitsPerHour.toLocaleString(),
          },
          botMetrics: {
            numberOfRobots: numberOfRobots.toString(),
            robotLph: robotLph.toFixed(2),
            robotUph: robotUph.toFixed(2),
            avgRobotMissionMin: formatMissionTime(avgRobotMissionMin),
          },
          putawayMetrics: {
            numberOfPickers: numberOfPickers.toFixed(1),
            inductDropoffFtes: inductDropoffFtes.toFixed(1),
            totalFtes: totalFtes.toFixed(1),
            purePutLph: purePutLph.toFixed(2),
            purePutUph: purePutUph.toFixed(2),
            totalFteLph: totalFteLph.toFixed(2),
            totalFteUph: totalFteUph.toFixed(2),
          },
          systemMetrics: {
            robotToPickerRatio,
            robotToFteRatio,
            pickArea: pickArea.toLocaleString(),
            areaPerBot: areaPerBot.toFixed(2),
            areaPerPicker: areaPerPicker.toFixed(2),
          },
        };
      } catch (error) {
        // console.log("Error in putawayOnlySummary calculation:", error);
        return {
          ops: {
            productiveHoursPerDay: "0.0 hrs",
          },
          designDemandVolume: {
            containersPerDay: "0",
            linesPerDay: "0",
            unitsPerDay: "0",
            containersPerHour: "0",
            linesPerHour: "0",
            unitsPerHour: "0",
          },
          botMetrics: {
            numberOfRobots: "0",
            robotLph: "0.00",
            robotUph: "0.00",
            avgRobotMissionMin: "00:00",
          },
          putawayMetrics: {
            numberOfPickers: "0.0",
            inductDropoffFtes: "0.0",
            totalFtes: "0.0",
            purePutLph: "0",
            purePutUph: "0",
            totalFteLph: "0",
            totalFteUph: "0",
          },
          systemMetrics: {
            robotToPickerRatio: "0:1",
            robotToFteRatio: "0:1",
            pickArea: "0",
            areaPerBot: "0",
            areaPerPicker: "0",
          },
        };
      }
    },
    description: "Calculates comprehensive putaway-only summary metrics",
    calculation: `putawayOnlySummary = {
      ops: {
        productiveHoursPerDay: from userInput
      },
      designDemandVolume: {
        containersPerDay: from userInput
        linesPerDay: from userInput
        unitsPerDay: from userInput
        containersPerHour: containersPerDay / productiveHours
        linesPerHour: linesPerDay / productiveHours
        unitsPerHour: unitsPerDay / productiveHours
      },
      botMetrics: {
        numberOfRobots: CEIL(totalRobots)
        robotLph: from botCalculations
        robotUph: from botCalculations
        avgRobotMissionMin: averageMissionTimeMin / 1440
      },
      putawayMetrics: {
        numberOfPickers: from botCalculations
        inductDropoffFtes: from botCalculations
        totalFtes: from botCalculations
        purePutLph: from botCalculations
        purePutUph: from botCalculations
        totalFteLph: designVolumeWithFactorUnitsPerHour / (totalFtes × unitsPerLine)
        totalFteUph: designVolumeWithFactorUnitsPerHour / totalFtes
      },
      systemMetrics: {
        robotToPickerRatio: numberOfRobots / numberOfPickers
        robotToFteRatio: numberOfRobots / totalFtes
        pickArea: from userInput
        areaPerBot: pickArea / numberOfRobots
        areaPerPicker: pickArea / numberOfPickers
      }
    }`,
    dependencies: [
      "productiveHoursPerDayPutaway",
      "designVolumes.ordersPerDay.putaway",
      "designVolumes.linesPerDay.putaway",
      "designVolumes.unitsPerDay.putaway",
      "totalRobots.putaway",
      "allRobotLphDesignVol.putaway",
      "allRobotUphDesignVol.putaway",
      "averageMissionTimeMin.putaway",
      "designDirectFtes.putaway",
      "designIndirectFtes.putaway",
      "totalDesignFtes.putaway",
      "lph.putaway",
      "uph.putaway",
      "designVolumeWithFactorUnitsPerHour.putaway",
      "unitsPerLine.putaway",
      "totalRobotArea",
    ],
  },
  robotUtilization: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        // Time available calculations
        const productiveHoursPicking =
          userInput?.productiveHoursPerDayPicking || 0;
        const productiveHoursPutaway =
          userInput?.productiveHoursPerDayPutaway || 0;
        const productiveMinutesPicking = productiveHoursPicking * 60;
        const productiveMinutesPutaway = productiveHoursPutaway * 60;

        // Robot counts
        const pickingRobots = Math.ceil(
          botCalculations?.totalRobots?.picking || 0,
        );
        const putawayRobots = Math.ceil(
          botCalculations?.totalRobots?.putaway || 0,
        );
        const totalRobots = pickingRobots + putawayRobots;

        // Mission times
        const avgPickingMissionMin =
          botCalculations?.averageMissionTimeMin?.picking || 0;
        const avgPutawayMissionMin =
          botCalculations?.averageMissionTimeMin?.putaway || 0;

        // Volume calculations
        const pickingLinesPerDay =
          userInput?.designVolumes?.linesPerDay?.picking || 0;
        const putawayLinesPerDay =
          userInput?.designVolumes?.linesPerDay?.putaway || 0;
        const pickingUnitsPerDay =
          userInput?.designVolumes?.unitsPerDay?.picking || 0;
        const putawayUnitsPerDay =
          userInput?.designVolumes?.unitsPerDay?.putaway || 0;

        // Picking utilization calculations
        const pickingMissionsPerDay = pickingLinesPerDay;
        const totalPickingMissionMinutes =
          pickingMissionsPerDay * avgPickingMissionMin;
        const totalPickingRobotMinutes =
          pickingRobots * productiveMinutesPicking;
        const pickingUtilization = totalPickingRobotMinutes
          ? (totalPickingMissionMinutes / totalPickingRobotMinutes) * 100
          : 0;

        // Putaway utilization calculations
        const putawayMissionsPerDay = putawayLinesPerDay;
        const totalPutawayMissionMinutes =
          putawayMissionsPerDay * avgPutawayMissionMin;
        const totalPutawayRobotMinutes =
          putawayRobots * productiveMinutesPutaway;
        const putawayUtilization = totalPutawayRobotMinutes
          ? (totalPutawayMissionMinutes / totalPutawayRobotMinutes) * 100
          : 0;

        // Combined utilization
        const totalMissionMinutes =
          totalPickingMissionMinutes + totalPutawayMissionMinutes;
        const totalRobotMinutes =
          totalPickingRobotMinutes + totalPutawayRobotMinutes;
        const overallUtilization = totalRobotMinutes
          ? (totalMissionMinutes / totalRobotMinutes) * 100
          : 0;

        // Productivity metrics
        const pickingLphPerRobot =
          pickingRobots && productiveHoursPicking
            ? pickingLinesPerDay / productiveHoursPicking / pickingRobots
            : 0;
        const putawayLphPerRobot =
          putawayRobots && productiveHoursPutaway
            ? putawayLinesPerDay / productiveHoursPutaway / putawayRobots
            : 0;
        const pickingUphPerRobot =
          pickingRobots && productiveHoursPicking
            ? pickingUnitsPerDay / productiveHoursPicking / pickingRobots
            : 0;
        const putawayUphPerRobot =
          putawayRobots && productiveHoursPutaway
            ? putawayUnitsPerDay / productiveHoursPutaway / putawayRobots
            : 0;

        return {
          robotCounts: {
            pickingRobots: pickingRobots.toString(),
            putawayRobots: putawayRobots.toString(),
            totalRobots: totalRobots.toString(),
          },
          timeAvailable: {
            productiveHoursPicking: `${productiveHoursPicking.toFixed(1)} hrs`,
            productiveHoursPutaway: `${productiveHoursPutaway.toFixed(1)} hrs`,
            productiveMinutesPicking: `${productiveMinutesPicking.toFixed(0)} min`,
            productiveMinutesPutaway: `${productiveMinutesPutaway.toFixed(0)} min`,
          },
          missionMetrics: {
            avgPickingMissionMin: `${avgPickingMissionMin.toFixed(1)} min`,
            avgPutawayMissionMin: `${avgPutawayMissionMin.toFixed(1)} min`,
            pickingMissionsPerDay: pickingMissionsPerDay.toLocaleString(),
            putawayMissionsPerDay: putawayMissionsPerDay.toLocaleString(),
          },
          utilization: {
            pickingUtilization: `${pickingUtilization.toFixed(1)}%`,
            putawayUtilization: `${putawayUtilization.toFixed(1)}%`,
            overallUtilization: `${overallUtilization.toFixed(1)}%`,
          },
          productivity: {
            pickingLphPerRobot: pickingLphPerRobot.toFixed(2),
            putawayLphPerRobot: putawayLphPerRobot.toFixed(2),
            pickingUphPerRobot: pickingUphPerRobot.toFixed(2),
            putawayUphPerRobot: putawayUphPerRobot.toFixed(2),
          },
          rawValues: {
            totalPickingMissionMinutes,
            totalPutawayMissionMinutes,
            totalPickingRobotMinutes,
            totalPutawayRobotMinutes,
            pickingUtilizationRaw: pickingUtilization,
            putawayUtilizationRaw: putawayUtilization,
            overallUtilizationRaw: overallUtilization,
          },
        };
      } catch (error) {
        // console.log("Error in robotUtilization calculation:", error);
        return {
          robotCounts: {
            pickingRobots: "0",
            putawayRobots: "0",
            totalRobots: "0",
          },
          timeAvailable: {
            productiveHoursPicking: "0.0 hrs",
            productiveHoursPutaway: "0.0 hrs",
            productiveMinutesPicking: "0 min",
            productiveMinutesPutaway: "0 min",
          },
          missionMetrics: {
            avgPickingMissionMin: "0.0 min",
            avgPutawayMissionMin: "0.0 min",
            pickingMissionsPerDay: "0",
            putawayMissionsPerDay: "0",
          },
          utilization: {
            pickingUtilization: "0.0%",
            putawayUtilization: "0.0%",
            overallUtilization: "0.0%",
          },
          productivity: {
            pickingLphPerRobot: "0",
            putawayLphPerRobot: "0",
            pickingUphPerRobot: "0",
            putawayUphPerRobot: "0",
          },
          rawValues: {
            totalPickingMissionMinutes: 0,
            totalPutawayMissionMinutes: 0,
            totalPickingRobotMinutes: 0,
            totalPutawayRobotMinutes: 0,
            pickingUtilizationRaw: 0,
            putawayUtilizationRaw: 0,
            overallUtilizationRaw: 0,
          },
        };
      }
    },
    description:
      "Calculates detailed robot utilization metrics for both picking and putaway operations",
    calculation: `robotUtilization = {
      robotCounts: {
        pickingRobots: CEIL(totalRobots.picking)
        putawayRobots: CEIL(totalRobots.putaway)
        totalRobots: pickingRobots + putawayRobots
      },
      timeAvailable: {
        productiveHoursPicking: from userInput
        productiveHoursPutaway: from userInput
        productiveMinutesPicking: productiveHoursPicking * 60
        productiveMinutesPutaway: productiveHoursPutaway * 60
      },
      missionMetrics: {
        avgPickingMissionMin: from botCalculations
        avgPutawayMissionMin: from botCalculations
        pickingMissionsPerDay: pickingLinesPerDay
        putawayMissionsPerDay: putawayLinesPerDay
      },
      utilization: {
        pickingUtilization: (totalPickingMissionMinutes / totalPickingRobotMinutes) * 100
        putawayUtilization: (totalPutawayMissionMinutes / totalPutawayRobotMinutes) * 100
        overallUtilization: (totalMissionMinutes / totalRobotMinutes) * 100
      },
      productivity: {
        pickingLphPerRobot: (pickingLinesPerDay / productiveHoursPicking) / pickingRobots
        putawayLphPerRobot: (putawayLinesPerDay / productiveHoursPutaway) / putawayRobots
        pickingUphPerRobot: (pickingUnitsPerDay / productiveHoursPicking) / pickingRobots
        putawayUphPerRobot: (putawayUnitsPerDay / productiveHoursPutaway) / putawayRobots
      }
    }`,
    dependencies: [
      "productiveHoursPerDayPicking",
      "productiveHoursPerDayPutaway",
      "designVolumes.linesPerDay.picking",
      "designVolumes.linesPerDay.putaway",
      "designVolumes.unitsPerDay.picking",
      "designVolumes.unitsPerDay.putaway",
      "totalRobots.picking",
      "totalRobots.putaway",
      "averageMissionTimeMin.picking",
      "averageMissionTimeMin.putaway",
    ],
  },
  spaceUtilization: {
    compute: (userInput, staticInputs, lookupReference, botCalculations) => {
      try {
        // Area calculations
        const totalRobotArea = userInput?.totalRobotArea || 0;
        const pickingArea = userInput?.pickingArea || 0;
        const putawayArea = userInput?.putawayArea || 0;
        const inductArea = userInput?.inductArea || 0;
        const dropoffArea = userInput?.dropoffArea || 0;
        const chargingArea = userInput?.chargingArea || 0;
        const totalOperationalArea =
          pickingArea + putawayArea + inductArea + dropoffArea + chargingArea;

        // Robot counts
        const pickingRobots = Math.ceil(
          botCalculations?.totalRobots?.picking || 0,
        );
        const putawayRobots = Math.ceil(
          botCalculations?.totalRobots?.putaway || 0,
        );
        const totalRobots = pickingRobots + putawayRobots;

        // Labor counts
        const pickingDirectFtes =
          botCalculations?.designDirectFtes?.picking || 0;
        const putawayDirectFtes =
          botCalculations?.designDirectFtes?.putaway || 0;
        const pickingIndirectFtes =
          botCalculations?.designIndirectFtes?.picking || 0;
        const putawayIndirectFtes =
          botCalculations?.designIndirectFtes?.putaway || 0;
        const totalPickingFtes = pickingDirectFtes + pickingIndirectFtes;
        const totalPutawayFtes = putawayDirectFtes + putawayIndirectFtes;
        const totalFtes = totalPickingFtes + totalPutawayFtes;

        // Area per robot calculations
        const areaPerRobot = totalRobots ? totalRobotArea / totalRobots : 0;
        const pickingAreaPerRobot = pickingRobots
          ? pickingArea / pickingRobots
          : 0;
        const putawayAreaPerRobot = putawayRobots
          ? putawayArea / putawayRobots
          : 0;

        // Area per FTE calculations
        const areaPerFte = totalFtes ? totalRobotArea / totalFtes : 0;
        const pickingAreaPerFte = totalPickingFtes
          ? pickingArea / totalPickingFtes
          : 0;
        const putawayAreaPerFte = totalPutawayFtes
          ? putawayArea / totalPutawayFtes
          : 0;

        // Area percentages
        const pickingAreaPercent = totalOperationalArea
          ? (pickingArea / totalOperationalArea) * 100
          : 0;
        const putawayAreaPercent = totalOperationalArea
          ? (putawayArea / totalOperationalArea) * 100
          : 0;
        const inductAreaPercent = totalOperationalArea
          ? (inductArea / totalOperationalArea) * 100
          : 0;
        const dropoffAreaPercent = totalOperationalArea
          ? (dropoffArea / totalOperationalArea) * 100
          : 0;
        const chargingAreaPercent = totalOperationalArea
          ? (chargingArea / totalOperationalArea) * 100
          : 0;

        // Density metrics
        const robotDensity = totalRobotArea
          ? (totalRobots / totalRobotArea) * 1000
          : 0; // Robots per 1000 sq ft
        const laborDensity = totalRobotArea
          ? (totalFtes / totalRobotArea) * 1000
          : 0; // FTEs per 1000 sq ft

        return {
          totalAreas: {
            totalRobotArea: `${totalRobotArea.toLocaleString()} sq ft`,
            pickingArea: `${pickingArea.toLocaleString()} sq ft`,
            putawayArea: `${putawayArea.toLocaleString()} sq ft`,
            inductArea: `${inductArea.toLocaleString()} sq ft`,
            dropoffArea: `${dropoffArea.toLocaleString()} sq ft`,
            chargingArea: `${chargingArea.toLocaleString()} sq ft`,
            totalOperationalArea: `${totalOperationalArea.toLocaleString()} sq ft`,
          },
          areaPerRobot: {
            overall: `${areaPerRobot.toFixed(2)} sq ft`,
            picking: `${pickingAreaPerRobot.toFixed(2)} sq ft`,
            putaway: `${putawayAreaPerRobot.toFixed(2)} sq ft`,
          },
          areaPerFte: {
            overall: `${areaPerFte.toFixed(2)} sq ft`,
            picking: `${pickingAreaPerFte.toFixed(2)} sq ft`,
            putaway: `${putawayAreaPerFte.toFixed(2)} sq ft`,
          },
          areaPercentages: {
            picking: `${pickingAreaPercent.toFixed(1)}%`,
            putaway: `${putawayAreaPercent.toFixed(1)}%`,
            induct: `${inductAreaPercent.toFixed(1)}%`,
            dropoff: `${dropoffAreaPercent.toFixed(1)}%`,
            charging: `${chargingAreaPercent.toFixed(1)}%`,
          },
          density: {
            robotsPer1000SqFt: `${robotDensity.toFixed(1)}`,
            ftesPer1000SqFt: `${laborDensity.toFixed(1)}`,
          },
          rawValues: {
            totalRobotAreaRaw: totalRobotArea,
            pickingAreaRaw: pickingArea,
            putawayAreaRaw: putawayArea,
            inductAreaRaw: inductArea,
            dropoffAreaRaw: dropoffArea,
            chargingAreaRaw: chargingArea,
            totalOperationalAreaRaw: totalOperationalArea,
            areaPerRobotRaw: areaPerRobot,
            areaPerFteRaw: areaPerFte,
            robotDensityRaw: robotDensity,
            laborDensityRaw: laborDensity,
          },
        };
      } catch (error) {
        // console.log("Error in spaceUtilization calculation:", error);
        return {
          totalAreas: {
            totalRobotArea: "0 sq ft",
            pickingArea: "0 sq ft",
            putawayArea: "0 sq ft",
            inductArea: "0 sq ft",
            dropoffArea: "0 sq ft",
            chargingArea: "0 sq ft",
            totalOperationalArea: "0 sq ft",
          },
          areaPerRobot: {
            overall: "0 sq ft",
            picking: "0 sq ft",
            putaway: "0 sq ft",
          },
          areaPerFte: {
            overall: "0 sq ft",
            picking: "0 sq ft",
            putaway: "0 sq ft",
          },
          areaPercentages: {
            picking: "0.0%",
            putaway: "0.0%",
            induct: "0.0%",
            dropoff: "0.0%",
            charging: "0.0%",
          },
          density: {
            robotsPer1000SqFt: "0.0",
            ftesPer1000SqFt: "0.0",
          },
          rawValues: {
            totalRobotAreaRaw: 0,
            pickingAreaRaw: 0,
            putawayAreaRaw: 0,
            inductAreaRaw: 0,
            dropoffAreaRaw: 0,
            chargingAreaRaw: 0,
            totalOperationalAreaRaw: 0,
            areaPerRobotRaw: 0,
            areaPerFteRaw: 0,
            robotDensityRaw: 0,
            laborDensityRaw: 0,
          },
        };
      }
    },
    description:
      "Calculates detailed space utilization metrics across different operational areas",
    calculation: `spaceUtilization = {
      totalAreas: {
        totalRobotArea: from userInput
        pickingArea: from userInput
        putawayArea: from userInput
        inductArea: from userInput
        dropoffArea: from userInput
        chargingArea: from userInput
        totalOperationalArea: sum of all areas
      },
      areaPerRobot: {
        overall: totalRobotArea / totalRobots
        picking: pickingArea / pickingRobots
        putaway: putawayArea / putawayRobots
      },
      areaPerFte: {
        overall: totalRobotArea / totalFtes
        picking: pickingArea / totalPickingFtes
        putaway: putawayArea / totalPutawayFtes
      },
      areaPercentages: {
        picking: (pickingArea / totalOperationalArea) * 100
        putaway: (putawayArea / totalOperationalArea) * 100
        induct: (inductArea / totalOperationalArea) * 100
        dropoff: (dropoffArea / totalOperationalArea) * 100
        charging: (chargingArea / totalOperationalArea) * 100
      },
      density: {
        robotsPer1000SqFt: (totalRobots / totalRobotArea) * 1000
        ftesPer1000SqFt: (totalFtes / totalRobotArea) * 1000
      }
    }`,
    dependencies: [
      "totalRobotArea",
      "pickingArea",
      "putawayArea",
      "inductArea",
      "dropoffArea",
      "chargingArea",
      "totalRobots.picking",
      "totalRobots.putaway",
      "designDirectFtes.picking",
      "designDirectFtes.putaway",
      "designIndirectFtes.picking",
      "designIndirectFtes.putaway",
    ],
    roundingRules: roundingRules,
  },
};


// Helper function to set nested value
const setNestedValue = (obj, path, value) => {
  const [category, type] = path.split(".");
  if (!obj[category]) {
    obj[category] = {};
  }
  obj[category][type] = value;
};

// Helper function to get nested value
const getNestedValue = (obj, path) => {
  const [category, type] = path.split(".");
  return obj[category]?.[type];
};

// Main calculation function
const calculateOutputs = (
  userInput,
  staticInputs,
  lookupReference,
  botCalculations,
) => {
  // Initialize with safe default values
  const results = {
    concurrentSummary: {
      ops: {
        productivePickingHoursPerDay: "0.0 hrs",
        productivePutawayHoursPerDay: "0.0 hrs",
      },
      designDemandVolume: {
        pickingOrdersPerHour: "0",
        putawayContainersPerHour: "0",
      },
      pickingBotMetrics: { numberOfPickingBots: "0", robotPickingLph: "0" },
      putawayBotMetrics: { numberOfPutawayBots: "0", robotPutawayLph: "0", avgRobotMissionMin: "0" },
      total: { totalNumberOfRobots: "0" },
      staffing: { numberOfDirectFtes: "0.0", totalFtes: "0.0" },
      systemMetrics: { robotToPickerRatio: "0:1", pickArea: "0" },
    },
    pickingOnlySummary: {
      ops: { productiveHoursPerDay: "0.0 hrs" },
      designDemandVolume: {
        ordersPerDay: "0",
        linesPerDay: "0",
        unitsPerDay: "0",
      },
      botMetrics: { numberOfRobots: "0", robotLph: "0.00", robotUph: "0.00" },
      pickingMetrics: { numberOfPickers: "0.0", totalFtes: "0.0" },
      systemMetrics: { robotToPickerRatio: "0:1", pickArea: "0" },
    },
    putawayOnlySummary: {
      ops: { productiveHoursPerDay: "0.0 hrs" },
      designDemandVolume: {
        containersPerDay: "0",
        linesPerDay: "0",
        unitsPerDay: "0",
      },
      botMetrics: { numberOfRobots: "0", robotLph: "0.00", robotUph: "0.00" },
      putawayMetrics: { numberOfPickers: "0.0", totalFtes: "0.0" },
      systemMetrics: { robotToPickerRatio: "0:1", pickArea: "0" },
    },
  };

  let iterations = 0;
  const MAX_ITERATIONS = 10;
  let hasChanges = true;

  // Keep calculating until no changes or max iterations reached
  while (hasChanges && iterations < MAX_ITERATIONS) {
    hasChanges = false;
    iterations++;

    for (const [key, calculator] of Object.entries(calculationMap)) {
      try {
        const newValue = calculator.compute(
          userInput,
          staticInputs,
          lookupReference,
          botCalculations,
        );

        if (JSON.stringify(results[key]) !== JSON.stringify(newValue)) {
          results[key] = newValue;
          hasChanges = true;
        }
      } catch (error) {
        // console.log(`Error calculating ${key}:`, error);
        // Keep the default value in results[key]
      }
    }
  }

  return {
    values: results,
    metadata: {
      iterations,
      calculationDescriptions: Object.fromEntries(
        Object.entries(calculationMap).map(([key, calc]) => [
          key,
          {
            description: calc.description,
            dependencies: calc.dependencies,
            calculation: calc.calculation,
          },
        ]),
      ),
    },
  };
};

// Export both the calculation map and the calculate function
export { calculationMap, calculateOutputs };

// Export the rounding utilities
export { roundingRules, applyRounding, getRoundingRule };
