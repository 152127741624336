import { fontWeight } from "@mui/system";

// Function to generate PowerBI themes from MUI theme
export const generatePowerBIThemes = (theme) => {

  // Helper function to create a solid color object
  const solid = (color) => ({ solid: { color } });

  // Dark theme color mapping - using MUI theme values
  const darkColors = {
    // Main backgrounds
    background: theme.palette.background.default,
    backgroundAlt: theme.palette.background.paper,
    backgroundDarker: theme.palette.grey[900],
    cardBackground: theme.palette.background.paper,
    
    // Text colors
    text: theme.palette.text.primary,
    textSecondary: theme.palette.text.secondary,
    textMuted: theme.palette.text.disabled,
    textBright: theme.palette.text.primary,
    
    // Borders and lines
    border: theme.palette.divider,
    gridline: theme.palette.divider,
    
    // Brand colors - using primary palette
    brandBlue1: theme.palette.primary.main,
    brandBlue2: theme.palette.primary.light,
    brandBlue3: theme.palette.primary.dark,
    brandBlue4: theme.palette.primary.dark,
    brandBlue5: theme.palette.primary.light,
    brandBlue6: theme.palette.primary.light,

    // Status colors
    error: theme.palette.error.main,
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,

    // Additional colors
    slicerOutline: theme.palette.grey[700],
    searchBorder: theme.palette.grey[600],
    hoverBlue: `${theme.palette.primary.main}3b`,
    pressedBlue: theme.palette.primary.main,
    redAccent: theme.palette.error.main,
    positiveDeltaBG: theme.palette.success.dark,
    positiveDeltaText: theme.palette.success.contrastText,
    positiveDeltaBorder: theme.palette.success.main,
    negativeDeltaBG: theme.palette.error.dark,
    negativeDeltaText: theme.palette.error.contrastText,
    negativeDeltaBorder: theme.palette.error.main,
  };

  // Create dark theme with all original visual styles
  const darkTheme = {
    name: "Dark Theme",
    visualStyles: {
      lineClusteredColumnComboChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          legend: [{
            show: true,
            position: "Top",
            fontFamily: "arial",
          }],
          categoryAxis: [{
            show: true,
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            show: true,
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            color: solid('#a9b2bb'),
            titleFontFamily: "arial",
            gridlineShow: true,
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
            secLabelColor: solid('#a9b2bb'),
            secTitleColor: solid('#a9b2bb'),
            secTitleFontFamily: "arial",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      actionButton: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          title: [{
            fontColor: solid('#fff'),
            background: solid('#fff'),
          }],
          visualHeader: [{
            show: true,
          }],
          outline: [{
            show: true,
          }, {
            $id: "disabled",
            weight: 5,
          }],
          fill: [{
            show: true,
          }, {
            $id: "default",
            transparency: 100,
            fillColor: solid('#262836'),
          }],
        },
      },
      columnChart: {
        "*": {
          general: [{
            responsive: true,
          }],
          legend: [{
            show: true,
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            titleColor: solid('#a9b2bb'),
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            color: solid('#a9b2bb'),
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      card: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          labels: [{
            color: solid('#fff'),
            fontSize: 18,
            fontFamily: "arial",
          }],
          categoryLabels: [{
            show: true,
            color: solid('#a9b2bb'),
            fontSize: 10,
            fontFamily: "arial",
          }],
          wordWrap: [{
            show: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            fontWeight: 800,
            alignment: "left",
            fontSize: 12,
            fontFamily: "arial",
          }],
        },
      },
      advanceCardE03760C5AB684758B56AA29F9E6C257B: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          dataLabelSettings: [{
            color: solid('#ffffffcf'),
            fontSize: 18,
            fontFamily: "arial",
          }],
          categoryLabelSettings: [{
            color: solid('#ffffffcf'),
            alignment: "left",
            fontSize: 12,
            fontFamily: "arial",
          }],
          title: [{
            show: true,
            fontColor: solid('#BDCAD6'),
            fontWeight: 800,
            alignment: "left",
            fontSize: 10,
            fontFamily: "arial",
          }],
        },
      },
      slicer: {
        "*": {
          general: [{
            outlineColor: solid(darkColors.slicerOutline),
            outlineWeight: 1,
            orientation: "vertical",
            keepLayerOrder: true,
            background: solid(darkColors.backgroundDarker),
          }],
          data: [{
            mode: "Basic",
            relativeRange: "",
            relativePeriod: "",
          }],
          selection: [{
            selectAllCheckboxEnabled: false,
            singleSelect: true,
          }],
          header: [{
            show: true,
            fontColor: solid(darkColors.text),
            outline: "None",
            textSize: 13,
            fontFamily: "arial",
          }],
          items: [{
            fontColor: solid(darkColors.text),
            background: solid(darkColors.background),
            outline: "None",
            textSize: 12,
            fontFamily: "sans-serif",
          }],
          slider: [{
            color: solid(theme.palette.error.main),
          }],
          background: [{
            show: true,
            color: solid(darkColors.background),
            transparency: 0,
          }],
          visualHeader: [{
            show: false,
            background: solid(darkColors.slicerOutline),
            border: solid(darkColors.slicerOutline),
            transparency: "20D",
            foreground: solid(darkColors.slicerOutline),
          }],
          title: [{
            show: true,
            fontColor: solid(darkColors.text),
            alignment: "left",
            fontSize: 12,
            fontFamily: "arial, sans-serif",
          }],
        },
      },
      page: {
        "*": {
          background: [{
            color: solid(darkColors.backgroundDarker),
            transparency: 100,
          }],
          outspace: [{
            color: solid(darkColors.backgroundDarker),
          }],
          wallpaper: [{
            color: solid(darkColors.backgroundDarker),
          }],
          displayArea: [{
            verticalAlignment: "Top",
            color: solid(darkColors.backgroundDarker),
          }],
          title: [{
            show: true,
            fontColor: solid(darkColors.textSecondary),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      textSearchSlicerF85E2E78BE4A4D7D9F99ED75B5D71C85: {
        "*": {
          generalFormatting: [{
            backgroundColor: solid('#262836'),
            fontColor: solid('#a9b2bb'),
            fontFamily: "Arial, sans-serif",
            fontSize: 12,
          }],
          inputFormatting: [{
            backgroundColor: solid('#262836'),
            fontColor: solid('#a9b2bb'),
            fontFamily: "arial",
            border: solid('#555'),
            padding: 8,
          }, {
            $id: "placeholder",
            fontColor: solid('#a9b2bb'),
          }],
          inputActionFormatting: [{
            backgroundColor: solid('#0078D4'),
            fontColor: solid('#FFFFFF'),
            hoverBackgroundColor: solid('#27aae1'),
            icon: "search",
          }],
          targetFormatting: [{
            backgroundColor: solid('#262836'),
            hoverBackgroundColor: solid('#27aae1'),
            selectedBackgroundColor: solid('#0078D4'),
            fontColor: solid('#a9b2bb'),
            selectedFontColor: solid('#FFFFFF'),
          }],
        },
      },
      pageNavigator: {
        "*": {
          fill: [{
            show: true,
          }, {
            $id: "default",
            transparency: 0,
            fillColor: solid('#1b2027'),
          }, {
            $id: "hover",
            transparency: 0,
            fillColor: solid('#0278cf3b'),
          }, {
            $id: "press",
            transparency: 0,
            fillColor: solid('#27aae1'),
          }, {
            $id: "selected",
            transparency: 0,
            fillColor: solid('#27aae1'),
          }, {
            $id: "disabled",
            transparency: 0,
            fillColor: solid('#FFFFFF'),
          }],
          text: [{
            show: true,
          }, {
            $id: "default",
            fontFamily: "arial",
            fontColor: solid('#27aae1'),
            horizontalAlignment: "center",
            verticalAlignment: "middle",
          }, {
            $id: "hover",
            fontColor: solid('#27aae1'),
            fontFamily: "arial",
          }, {
            $id: "press",
            fontColor: solid('#000000'),
            fontFamily: "arial",
          }, {
            $id: "selected",
            fontColor: solid('#000000'),
            fontFamily: "arial",
          }, {
            $id: "disabled",
            fontColor: solid('#FFFFFF'),
            fontFamily: "arial",
          }],
        },
      },
      mulltiMeasure6975758CD8B3457482EB5980A4ACDF83_OrgStore: {
        "*": {
          visual: [{
            titleFontSize: 14,
          }, {
            titleColor: solid('#BDCAD6'),
          }, {
            valueFontSize: 28,
          }, {
            valueColor: solid('#FDFEFE'),
          }, {
            unitsFontSize: 14,
          }, {
            unitsColor: solid('#FDFEFE'),
          }, {
            subtextFontSize: 14,
          }, {
            subtextColor: solid('#BDCAD6'),
          }, {
            backgroundColor: solid('#262836'),
          }, {
            borderColor: solid('#1a2027'),
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      "*": {
        "*": {
          outspacePane: [{
            backgroundColor: solid('#1a2027'),
            foregroundColor: solid('#1a2027'),
            checkboxAndApplyColor: solid('#1a2027'),
          }],
          tooltips: [{
            fontFamily: "Arial, sans-serif",
            fontSize: 12,
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          filterCard: [{
            $id: "Applied",
            backgroundColor: solid('#262836'),
            foregroundColor: solid('#262836'),
            transparency: 0,
          }, {
            $id: "Available",
            backgroundColor: solid('#262836'),
            foregroundColor: solid('#262836'),
            transparency: 0,
          }],
          background: [{
            color: solid('#262836'),
          }],
          visualHeader: [{
            foreground: solid('#0078CF'),
            border: solid('#fff'),
            background: solid('#fff'),
            transparency: 0,
            showVisualInformationButton: false,
            showVisualWarningButton: false,
            showVisualErrorButton: false,
            showDrillRoleSelector: false,
            showDrillUpButton: false,
            showDrillToggleButton: false,
            showDrillDownLevelButton: false,
            showDrillDownExpandButton: false,
            showPinButton: false,
            showFocusModeButton: false,
            showFilterRestatementButton: false,
            showSeeDataLayoutToggleButton: false,
            showOptionsMenu: true,
            showTooltipButton: false,
          }],
          visualHeaderTooltip: [{
            titleFontColor: solid('#fff'),
            fontFamily: "arial",
            transparency: 0,
            background: solid('#1a2027'),
          }],
          visualTooltip: [{
            titleFontColor: solid('#fff'),
            fontFamily: "arial",
            valueFontColor: solid('#fff'),
            background: solid('#1a2027'),
          }],
          dropShadow: [{
            position: "Outer",
            show: false,
            color: solid('#a9b2bb000'),
          }],
        },
      },
      exportDataDialog: {
        "*": {
          background: [{
            color: '#1a2027',
          }],
          foreground: [{
            color: '#1a2027',
          }],
          border: [{
            color: '#262836',
          }],
          title: [{
            fontSize: 16,
            color: '#ffffff',
          }],
          button: [{
            background: {
              color: '#0078cf',
            },
            foreground: {
              color: '#ffffff',
            },
          }, {
            hover: {
              background: {
                color: '#262836',
              },
              foreground: {
                color: '#ffffff',
              },
            },
          }, {
            pressed: {
              background: {
                color: '#262836',
              },
              foreground: {
                color: '#ffffff',
              },
            },
          }],
        },
      },
      page: {
        "*": {
          background: [{
            color: solid('#1a2027'),
            transparency: 100,
          }],
          outspace: [{
            color: solid('#1a2027'),
          }],
          wallpaper: [{
            color: solid('#1a2027'),
          }],
          displayArea: [{
            verticalAlignment: "Top",
            color: solid('#1a2027'),
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      clusteredColumnChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            show: true,
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 10,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            axisStyle: "showTitleOnly",
            titleColor: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            show: true,
            position: "Right",
            axisScale: "Linear",
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            color: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontSize: 11,
            fontFamily: "arial",
            enableBackground: true,
            backgroundColor: solid('#1b2027'),
            backgroundTransparency: 50,
          }],
          plotArea: [{
            transparency: 20,
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      clusteredBarChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 10,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            titleColor: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            axisStyle: "showTitleOnly",
            color: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            labelPrecision: 1,
            labelPosition: "OutsideEnd",
            fontSize: 11,
            fontFamily: "arial",
            backgroundColor: solid('#262836'),
          }],
          plotArea: [{
            transparency: 20,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      multiRowCard: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          border: [{
            color: solid('#808080'),
            show: false,
            radius: 0,
          }],
          title: [{
            color: solid('#fff'),
            fontSize: 14,
            fontFamily: "arial",
          }],
          dataLabels: [{
            color: solid('#fff'),
            fontSize: 12,
            fontFamily: "arial",
          }],
          categoryLabels: [{
            show: true,
            color: solid('#a9b2bb'),
            fontSize: 8,
            fontFamily: "arial",
          }],
          cardTitle: [{
            color: solid('#a9b2bb'),
            fontSize: 12,
            fontFamily: "arial",
          }],
          card: [{
            outline: "TopOnly",
            outlineColor: solid('#a9b2bb'),
            outlineWeight: 2,
            barShow: false,
            barColor: solid('#262836'),
            barWeight: 2,
            cardPadding: 1,
            cardBackground: solid('#262836'),
          }],
        },
      },
      scatterChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            color: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            color: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
          }],
          categoryLabels: [{
            color: solid('#a9b2bb'),
            fontSize: 11,
            fontFamily: "arial",
            backgroundColor: solid('#262836'),
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      pieChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          border: [{
            color: solid('#808080'),
            show: false,
            radius: 0,
          }],
          title: [{
            fontColor: solid('#a9b2bb'),
            background: solid('#FFFFFF'),
            alignment: "left",
          }],
          legend: [{
            legendColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 8,
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontSize: 10,
            fontFamily: "arial",
          }],
        },
      },
      stackedAreaChart: {
        "*": {
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            titleColor: solid('#a9b2bb'),
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            color: solid('a9b2bb'),
            titleFontFamily: "arial",
            gridlineShow: true,
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
          }],
          labels: [{
            show: true,
            color: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          lineStyles: [{
            strokeWidth: 1,
            lineStyle: "solid",
          }],
          plotArea: [{}],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      lineStackedColumnComboChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 12,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            concatenateLabels: true,
            titleColor: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            labelPrecision: "Auto",
            color: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
            secLabelColor: solid('#a9b2bb'),
            secFontSize: 12,
            secFontFamily: "arial",
            secLabelPrecision: 2,
            secTitleColor: solid('#a9b2bb'),
            secTitleFontSize: 10,
            secTitleFontFamily: "arial",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontSize: 11,
            fontFamily: "arial",
            enableBackground: true,
            backgroundColor: solid('#262836'),
          }],
          lineStyles: [{
            markerColor: solid('#a9b2bb'),
            lineStyle: "solid",
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      barChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            titleColor: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontSize: 14,
            fontFamily: "arial",
            labelPrecision: "Auto",
            titleColor: solid('#a9b2bb'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontSize: 11,
            fontFamily: "arial",
            backgroundColor: solid('#262836'),
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      areaChart: {
        "*": {
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            titleColor: solid('#a9b2bb'),
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
            color: solid('#a9b2bb'),
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          lineStyles: [{
            lineStyle: "solid",
            markerColor: solid('#a9b2bb'),
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      donutChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          labels: [{
            color: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          slices: [{
            innerRadiusRatio: 90,
          }],
        },
      },
      textSearchSlicerF85E2E78BE4A4D7D9F99ED75B5D71C85: {
        "*": {
          generalFormatting: [{
            backgroundColor: solid('#262836'),
            fontColor: solid('#a9b2bb'),
            fontFamily: "Arial, sans-serif",
            fontSize: 12,
          }],
          inputFormatting: [{
            backgroundColor: solid('#262836'),
            fontColor: solid('#a9b2bb'),
            fontFamily: "arial",
            border: solid('#555'),
            padding: 8,
          }, {
            $id: "placeholder",
            fontColor: solid('#a9b2bb'),
          }],
          inputActionFormatting: [{
            backgroundColor: solid('#0078D4'),
            fontColor: solid('#FFFFFF'),
            hoverBackgroundColor: solid('#27aae1'),
            icon: "search",
          }],
          targetFormatting: [{
            backgroundColor: solid('#262836'),
            hoverBackgroundColor: solid('#27aae1'),
            selectedBackgroundColor: solid('#0078D4'),
            fontColor: solid('#a9b2bb'),
            selectedFontColor: solid('#FFFFFF'),
          }],
        },
      },
      standAloneLegend_OrgStore: {
        "*": {
          visual: [{
            fontSize: 14,
            dashFontSize: 22,
            symbolFontSize: 16,
            fontColor: solid('#A9B2BB'),
            backgroundColor: solid('#262836'),
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      dynamicVisual6975758CD8B3457482EB5980A4ACDF83_OrgStore: {
        "*": {
          visual: [{
            cardBackgroundColor: solid('#252836'),
          }, {
            cardBorderColor: solid('#6A6D8D'),
          }, {
            cardTitleColor: solid('#BDCAD6'),
          }, {
            positiveDeltaBGColor: solid('#460001'),
          }, {
            positiveDeltaColor: solid('#FCF5F5'),
          }, {
            positiveDeltaBorderColor: solid('#B10E0F'),
          }, {
            negativeDeltaBGColor: solid('#392D0C'),
          }, {
            negativeDeltaColor: solid('#FFF8E1'),
          }, {
            negativeDeltaBorderColor: solid('#B48E1E'),
          }, {
            zoneTitleColor: solid('#FDFEFE'),
          }],
          background: [{
            show: true,
            color: solid('#fff'),
            transparency: 100,
          }],
        },
      },
      Datepicker_1687358625_Powerviz_OrgStore: {
        "*": {
          title: [{
            show: true,
            fontColor: solid('#ffffff'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      pageNavigator: {
        "*": {
          fill: [{
            show: true,
          }, {
            $id: "default",
            transparency: 0,
            fillColor: solid('#1b2027'),
          }, {
            $id: "hover",
            transparency: 0,
            fillColor: solid('#0278cf3b'),
          }, {
            $id: "press",
            transparency: 0,
            fillColor: solid('#27aae1'),
          }, {
            $id: "selected",
            transparency: 0,
            fillColor: solid('#27aae1'),
          }, {
            $id: "disabled",
            transparency: 0,
            fillColor: solid('#FFFFFF'),
          }],
          text: [{
            show: true,
          }, {
            $id: "default",
            fontFamily: "arial",
            fontColor: solid('#27aae1'),
            horizontalAlignment: "center",
            verticalAlignment: "middle",
          }, {
            $id: "hover",
            fontColor: solid('#27aae1'),
            fontFamily: "arial",
          }, {
            $id: "press",
            fontColor: solid('#000000'),
            fontFamily: "arial",
          }, {
            $id: "selected",
            fontColor: solid('#000000'),
            fontFamily: "arial",
          }, {
            $id: "disabled",
            fontColor: solid('#FFFFFF'),
            fontFamily: "arial",
          }],
        },
      },
      mulltiMeasure6975758CD8B3457482EB5980A4ACDF83_OrgStore: {
        "*": {
          visual: [{
            titleFontSize: 14,
          }, {
            titleColor: solid('#BDCAD6'),
          }, {
            valueFontSize: 28,
          }, {
            valueColor: solid('#FDFEFE'),
          }, {
            unitsFontSize: 14,
          }, {
            unitsColor: solid('#FDFEFE'),
          }, {
            subtextFontSize: 14,
          }, {
            subtextColor: solid('#BDCAD6'),
          }, {
            backgroundColor: solid('#262836'),
          }, {
            borderColor: solid('#1a2027'),
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
        },
      },
      "*": {
        "*": {
          outspacePane: [{
            backgroundColor: solid('#1a2027'),
            foregroundColor: solid('#1a2027'),
            checkboxAndApplyColor: solid('#1a2027'),
          }],
          tooltips: [{
            fontFamily: "Arial, sans-serif",
            fontSize: 12,
          }],
          legend: [{
            labelColor: solid('#a9b2bb'),
            fontFamily: "arial",
          }],
          filterCard: [{
            $id: "Applied",
            backgroundColor: solid('#262836'),
            foregroundColor: solid('#262836'),
            transparency: 0,
          }, {
            $id: "Available",
            backgroundColor: solid('#262836'),
            foregroundColor: solid('#262836'),
            transparency: 0,
          }],
          background: [{
            color: solid('#262836'),
          }],
          visualHeader: [{
            foreground: solid('#0078CF'),
            border: solid('#fff'),
            background: solid('#fff'),
            transparency: 0,
            showVisualInformationButton: false,
            showVisualWarningButton: false,
            showVisualErrorButton: false,
            showDrillRoleSelector: false,
            showDrillUpButton: false,
            showDrillToggleButton: false,
            showDrillDownLevelButton: false,
            showDrillDownExpandButton: false,
            showPinButton: false,
            showFocusModeButton: false,
            showFilterRestatementButton: false,
            showSeeDataLayoutToggleButton: false,
            showOptionsMenu: true,
            showTooltipButton: false,
          }],
          visualHeaderTooltip: [{
            titleFontColor: solid('#fff'),
            fontFamily: "arial",
            transparency: 0,
            background: solid('#1a2027'),
          }],
          visualTooltip: [{
            titleFontColor: solid('#fff'),
            fontFamily: "arial",
            valueFontColor: solid('#fff'),
            background: solid('#1a2027'),
          }],
          dropShadow: [{
            position: "Outer",
            show: false,
            color: solid('#a9b2bb000'),
          }],
        },
      },
      exportDataDialog: {
        "*": {
          background: [{
            color: '#1a2027',
          }],
          foreground: [{
            color: '#1a2027',
          }],
          border: [{
            color: '#262836',
          }],
          title: [{
            fontSize: 16,
            color: '#ffffff',
          }],
          button: [{
            background: {
              color: '#0078cf',
            },
            foreground: {
              color: '#ffffff',
            },
          }, {
            hover: {
              background: {
                color: '#262836',
              },
              foreground: {
                color: '#ffffff',
              },
            },
          }, {
            pressed: {
              background: {
                color: '#262836',
              },
              foreground: {
                color: '#ffffff',
              },
            },
          }],
        },
      },
      page: {
        "*": {
          background: [{
            color: solid('#1a2027'),
            transparency: 100,
          }],
          outspace: [{
            color: solid('#1a2027'),
          }],
          wallpaper: [{
            color: solid('#1a2027'),
          }],
          displayArea: [{
            verticalAlignment: "Top",
            color: solid('#1a2027'),
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      tableEx: {
        "*": {
          grid: {
            "*": {
              row: [{
                show: true,
                color: solid('#252836'),
                padding: 5,
              }],
              rowAlternating: [{
                show: true,
                color: solid('#262f3d'),
              }],
            },
          },
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#262836'),
            transparency: 0,
          }],
          border: [{
            show: false,
            radius: 0,
          }],
          title: [{
            show: true,
            fontColor: solid('#a9b2bb'),
            background: solid('#262836'),
            alignment: "left",
            fontSize: 16,
          }],
          grid: [{
            gridVertical: true,
            gridVerticalColor: solid('#1A2027'),
            gridVerticalWeight: 1,
            gridHorizontal: true,
            gridHorizontalColor: solid('#1A2027'),
            gridHorizontalWeight: 1,
            rowPadding: 1,
            outlineColor: solid('#1A2027'),
            outlineWeight: 1,
            textSize: 10,
            imageHeight: 100,
          }],
          columnHeaders: [{
            fontColor: solid('#fff'),
            backColor: solid('#1A2027'),
            outline: "Frame",
            autoSizeColumnWidth: true,
            fontFamily: "arial",
            fontSize: 12,
            alignment: "Left",
            wordWrap: true,
          }],
          values: [{
            fontColorPrimary: solid('#fff'),
            backColorPrimary: solid('#262836'),
            fontColorSecondary: solid('#fff'),
            backColorSecondary: solid('#262836'),
            outline: "Frame",
            urlIcon: true,
            wordWrap: true,
            fontFamily: "arial",
            fontSize: 10,
          }],
          total: [{
            totals: true,
            fontColor: solid('#fff'),
            backColor: solid('#262836'),
            outline: "Frame",
            fontFamily: "arial",
            fontSize: 12,
          }],
        },
      },
    },
    dataColors: [
      "#27aae1",
      "#78e1e8",
      "#0980bc",
      "#003975",
      "#238ab7",
      "#6fc2df",
    ],
    textClasses: {
      label: {
        fontFace: "arial",
        color: darkColors.textSecondary,
        fontSize: 10,
      },
      callout: {
        color: darkColors.textSecondary,
        fontSize: 9,
        fontFace: "arial",
      },
      title: {
        color: darkColors.textSecondary,
      },
      header: {
        color: darkColors.textSecondary,
        fontSize: 9,
        fontFace: "arial",
      },
    },
    good: theme.palette.success.main,
    neutral: theme.palette.grey[500],
    bad: theme.palette.error.main,
    maximum: theme.palette.primary.dark,
    center: theme.palette.grey[500],
    minimum: theme.palette.primary.light,
  };

  // Light theme color mapping - using MUI theme values
  const lightColors = {
    // Main backgrounds
    background: '#fff',
    backgroundAlt: '#eef2f6',
    backgroundDarker: '#eef2f6',
    cardBackground: '#fff',
    
    // Text colors
    text: '#364152',
    textSecondary: '#687485',
    textMuted: theme.palette.text.disabled,
    textBright: '#364152',
    
    // Borders and lines
    border: '#E3E8EF',
    gridline: '#e3e8ef',
    
    // Brand colors - using primary palette
    brandBlue1: theme.palette.primary.main,
    brandBlue2: theme.palette.primary.light,
    brandBlue3: theme.palette.primary.dark,
    brandBlue4: theme.palette.primary.dark,
    brandBlue5: theme.palette.primary.light,
    brandBlue6: theme.palette.primary.light,

    // Status colors
    error: '#FF373A',
    warning: theme.palette.warning.main,
    success: theme.palette.success.main,

    // Additional colors
    slicerOutline: '#444444',
    searchBorder: theme.palette.grey[500],
    hoverBlue: '#0278cf3b',
    pressedBlue: '#0078CF',
  };

  // Create base visual styles that will be used for both themes
  const createBaseVisualStyles = (colors) => ({
    "*": {
      "*": {
        background: [{
          show: true,
          color: solid(colors.background),
          transparency: 0,
        }],
        foreground: [{
          show: true,
          color: solid(colors.text),
        }],
        border: [{
          show: true,
          color: solid(colors.border),
        }],
        title: [{
          show: true,
          fontColor: solid(colors.text),
          alignment: "left",
          fontSize: 13,
          fontFamily: "arial",
        }],
        legend: [{
          show: true,
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
        }],
        categoryAxis: [{
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
          titleColor: solid(colors.textSecondary),
        }],
        valueAxis: [{
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
          gridlineColor: solid(colors.gridline),
        }],
        labels: [{
          color: solid(colors.textSecondary),
          fontFamily: "arial",
        }],
      }
    },
    lineChart: {
      "*": {
        general: [{
          responsive: true,
        }],
        categoryAxis: [{
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
        }],
        valueAxis: [{
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
          gridlineColor: solid(colors.gridline),
        }],
      }
    },
    columnChart: {
      "*": {
        general: [{
          responsive: true,
        }],
        categoryAxis: [{
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
        }],
        valueAxis: [{
          labelColor: solid(colors.textSecondary),
          fontFamily: "arial",
          gridlineColor: solid(colors.gridline),
        }],
      }
    },
    card: {
      "*": {
        general: [{
          responsive: true,
        }],
        labels: [{
          color: solid(colors.text),
          fontFamily: "arial",
        }],
        categoryLabels: [{
          color: solid(colors.textSecondary),
          fontFamily: "arial",
        }],
      }
    },
    slicer: {
      "*": {
        general: [{
          outlineColor: solid(colors.slicerOutline),
          background: solid(colors.backgroundDarker),
        }],
        header: [{
          fontColor: solid(colors.text),
          fontFamily: "arial",
        }],
        items: [{
          fontColor: solid(colors.text),
          background: solid(colors.background),
          fontFamily: "arial",
        }],
      }
    },
    page: {
      "*": {
        background: [{
          color: solid(colors.backgroundAlt),
        }],
        outspace: [{
          color: solid(colors.backgroundAlt),
        }],
        visualHeader: [{
          background: solid(colors.backgroundAlt),
          foreground: solid(colors.text),
          border: solid(colors.border),
        }],
      }
    }
  });

  // Create themes with base visual styles
  const lightTheme = {
    name: "Light Theme",
    visualStyles: {
      lineClusteredColumnComboChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            show: true,
            position: "Top",
            fontFamily: "arial",
          }],
          categoryAxis: [{
            show: true,
            labelColor: solid('#687485'),
            fontFamily: "arial",
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            show: true,
            labelColor: solid('#687485'),
            fontFamily: "arial",
            color: solid('#687485'),
            titleFontFamily: "arial",
            gridlineShow: true,
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
            secLabelColor: solid('#687485'),
            secTitleColor: solid('#687485'),
            secTitleFontFamily: "arial",
          }],
          labels: [{
            color: solid('#687485'),
            fontFamily: "arial",
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      actionButton: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            color: solid('#fff'),
          }],
          title: [{
            fontColor: solid('#364152'),
            background: solid('#fff'),
          }],
          visualHeader: [{
            show: true,
          }],
          outline: [{
            show: true,
          }, {
            $id: "disabled",
            weight: 5,
          }],
          fill: [{
            show: true,
          }, {
            $id: "default",
            transparency: 100,
            fillColor: solid('#fff'),
          }],
        },
      },
      columnChart: {
        "*": {
          general: [{
            responsive: true,
          }],
          legend: [{
            show: true,
            labelColor: solid('#687485'),
            fontFamily: "arial",
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            titleColor: solid('#687485'),
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            color: solid('#687485'),
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#000'),
            fontFamily: "arial",
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      advanceCardE03760C5AB684758B56AA29F9E6C257B: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          dataLabelSettings: [{
            color: solid('#364152eb'),
            fontSize: 18,
            fontFamily: "arial",
          }],
          categoryLabelSettings: [{
            color: solid('#364152eb'),
            alignment: "left",
            fontSize: 12,
            fontFamily: "arial",
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 10,
            fontFamily: "arial",
          }],
        },
      },
      clusteredColumnChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            show: true,
            labelColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontSize: 10,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            axisStyle: "showTitleOnly",
            titleColor: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            show: true,
            position: "Right",
            axisScale: "Linear",
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            color: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#687485'),
            fontSize: 11,
            fontFamily: "arial",
            enableBackground: true,
            backgroundColor: solid('#eef2f6'),
            backgroundTransparency: 0,
          }],
          plotArea: [{
            transparency: 20,
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      clusteredBarChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontSize: 10,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            titleColor: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            axisStyle: "showTitleOnly",
            color: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#687485'),
            labelPrecision: 1,
            labelPosition: "OutsideEnd",
            fontSize: 11,
            fontFamily: "arial",
            backgroundColor: solid('#fff'),
          }],
          plotArea: [{
            transparency: 20,
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      card: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          labels: [{
            color: solid('#364152'),
            fontSize: 18,
            fontFamily: "arial",
          }],
          categoryLabels: [{
            show: true,
            color: solid('#687485'),
            fontSize: 10,
            fontFamily: "arial",
          }],
          wordWrap: [{
            show: true,
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            fontWeight: 800,
            alignment: "left",
            fontSize: 12,
            fontFamily: "arial",
          }],
        },
      },
      slicer: {
        "*": {
          general: [{
            outlineColor: solid('#444444'),
            outlineWeight: 1,
            orientation: "vertical",
            keepLayerOrder: true,
            background: solid('#687485'),
          }],
          data: [{
            mode: "Basic",
            relativeRange: "",
            relativePeriod: "",
          }],
          selection: [{
            selectAllCheckboxEnabled: false,
            singleSelect: true,
          }],
          header: [{
            show: true,
            fontColor: solid('#364152'),
            outline: "None",
            textSize: 13,
            fontFamily: "arial",
          }],
          items: [{
            fontColor: solid('#364152'),
            background: solid('#f9f9f9'),
            outline: "None",
            textSize: 12,
            fontFamily: "sans-serif",
          }],
          slider: [{
            color: solid('#FF373A'),
          }],
          background: [{
            show: true,
            color: solid('#fff'),
            transparency: 100,
          }],
          visualHeader: [{
            show: false,
            background: solid('#444444'),
            border: solid('#444444'),
            transparency: "20D",
            foreground: solid('#444444'),
            showVisualInformationButton: false,
            showVisualWarningButton: false,
            showVisualErrorButton: false,
            showDrillRoleSelector: false,
            showDrillUpButton: false,
            showDrillToggleButton: false,
            showDrillDownLevelButton: false,
            showDrillDownExpandButton: false,
            showPinButton: false,
            showFocusModeButton: false,
            showFilterRestatementButton: false,
            showSeeDataLayoutToggleButton: false,
            showOptionsMenu: false,
            showTooltipButton: false,
          }],
          title: [{
            show: true,
            fontColor: solid('#364152'),
            alignment: "left",
            fontSize: 12,
            fontFamily: "arial, sans-serif",
          }],
        },
      },
      page: {
        "*": {
          background: [{
            color: solid(lightColors.backgroundAlt),
          }],
          outspace: [{
            color: solid(lightColors.backgroundAlt),
          }],
          visualHeader: [{
            background: solid(lightColors.backgroundAlt),
            foreground: solid(lightColors.text),
            border: solid(lightColors.border),
          }],
        }
      },
      tableEx: {
        "*": {
          grid: {
            "*": {
              row: [{
                show: true,
                color: solid('#FFFFFF'),
                padding: 5,
              }],
              rowAlternating: [{
                show: true,
                color: solid('#fff'),
              }],
            },
          },
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#FFFFFF'),
            transparency: 0,
          }],
          border: [{
            show: false,
            radius: 0,
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 16,
          }],
          grid: [{
            gridVertical: true,
            gridVerticalWeight: 1,
            gridHorizontal: true,
            gridHorizontalWeight: 1,
            rowPadding: 1,
            outlineColor: solid('#eee'),
            outlineWeight: 1,
            textSize: 10,
            imageHeight: 100,
          }],
          columnHeaders: [{
            fontColor: solid('#687485'),
            backColor: solid('#fff'),
            outline: "Frame",
            autoSizeColumnWidth: true,
            fontFamily: "arial",
            fontSize: 12,
            alignment: "Left",
            wordWrap: true,
          }],
          values: [{
            outline: "Frame",
            urlIcon: true,
            wordWrap: true,
            fontFamily: "arial",
            fontSize: 10,
          }],
          total: [{
            totals: true,
            outline: "Frame",
            fontFamily: "arial",
            fontSize: 12,
          }],
        },
      },
      multiRowCard: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#fff'),
            transparency: 0,
          }],
          border: [{
            color: solid('#808080'),
            show: false,
            radius: 0,
          }],
          title: [{
            color: solid('#364152'),
            fontSize: 14,
            fontFamily: "arial",
          }],
          dataLabels: [{
            color: solid('#000000'),
            fontSize: 12,
            fontFamily: "arial",
          }],
          categoryLabels: [{
            show: true,
            color: solid('#364152'),
            fontSize: 8,
            fontFamily: "arial",
          }],
          cardTitle: [{
            color: solid('#364152'),
            fontSize: 12,
            fontFamily: "arial",
          }],
          card: [{
            outline: "TopOnly",
            outlineColor: solid('#fff'),
            outlineWeight: 2,
            barShow: false,
            barColor: solid('#01B8AA'),
            barWeight: 2,
            cardPadding: 10,
            cardBackground: solid('#fff'),
          }],
        },
      },
      scatterChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            color: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            color: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 2,
            gridlineStyle: "solid",
          }],
          categoryLabels: [{
            color: solid('#687485'),
            fontSize: 11,
            fontFamily: "arial",
            backgroundColor: solid('#fff'),
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      pieChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          background: [{
            show: true,
            color: solid('#FFFFFF'),
            transparency: 0,
          }],
          border: [{
            color: solid('#808080'),
            show: false,
            radius: 0,
          }],
          title: [{
            fontColor: solid('#687485'),
            background: solid('#FFFFFF'),
            alignment: "left",
          }],
          legend: [{
            legendColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 8,
          }],
          labels: [{
            color: solid('#687485'),
            fontSize: 10,
            fontFamily: "arial",
          }],
        },
      },
      stackedAreaChart: {
        "*": {
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            titleColor: solid('#687485'),
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            color: solid('687485'),
            titleFontFamily: "arial",
            gridlineShow: true,
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
          }],
          labels: [{
            show: true,
            color: solid('#687485'),
            fontFamily: "arial",
          }],
          lineStyles: [{
            strokeWidth: 1,
            lineStyle: "solid",
          }],
          plotArea: [{}],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      lineStackedColumnComboChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontSize: 12,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            concatenateLabels: true,
            titleColor: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            labelPrecision: "Auto",
            color: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
            secLabelColor: solid('#687485'),
            secFontSize: 12,
            secFontFamily: "arial",
            secLabelPrecision: 2,
            secTitleColor: solid('#687485'),
            secTitleFontSize: 10,
            secTitleFontFamily: "arial",
          }],
          labels: [{
            color: solid('#687485'),
            fontSize: 11,
            fontFamily: "arial",
            enableBackground: true,
            backgroundColor: solid('#fff'),
          }],
          lineStyles: [{
            markerColor: solid('#687485'),
            lineStyle: "solid",
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      barChart: {
        "*": {
          general: [{
            responsive: true,
            keepLayerOrder: true,
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            preferredCategoryWidth: 30,
            maxMarginFactor: 28,
            innerPadding: 22,
            titleColor: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontSize: 14,
            fontFamily: "arial",
            labelPrecision: "Auto",
            titleColor: solid('#687485'),
            titleFontSize: 12,
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineThickness: 1,
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#687485'),
            fontSize: 11,
            fontFamily: "arial",
            backgroundColor: solid('#fff'),
          }],
        },
      },
      areaChart: {
        "*": {
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            fontSize: 10,
          }],
          categoryAxis: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            titleColor: solid('#687485'),
            titleFontFamily: "arial",
          }],
          valueAxis: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
            color: solid('#687485'),
            titleFontFamily: "arial",
            gridlineColor: solid('#e3e8ef'),
            gridlineStyle: "solid",
          }],
          labels: [{
            color: solid('#687485'),
            fontFamily: "arial",
          }],
          lineStyles: [{
            lineStyle: "solid",
            markerColor: solid('#687485'),
          }],
          title: [{
            show: true,
            fontColor: solid('#687485'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      standAloneLegend_OrgStore: {
        "*": {
          visual: [{
            fontSize: 14,
            dashFontSize: 22,
            symbolFontSize: 16,
            fontColor: solid('#687485'),
            backgroundColor: solid('#fff'),
          }],
          background: [{
            show: true,
            color: solid('#fff'),
            transparency: 0,
          }],
        },
      },
      dynamicVisual6975758CD8B3457482EB5980A4ACDF83_OrgStore: {
        "*": {
          visual: [{
            cardBackgroundColor: solid('#FFF'),
          }, {
            cardBorderColor: solid('#E3E8EF'),
          }, {
            cardTitleColor: solid('#687485'),
          }, {
            positiveDeltaBGColor: solid('#FCF5F5'),
          }, {
            positiveDeltaColor: solid('#850001'),
          }, {
            positiveDeltaBorderColor: solid('#BF3030'),
          }, {
            negativeDeltaBGColor: solid('#FFF8E1'),
          }, {
            negativeDeltaColor: solid('#8D6E10'),
          }, {
            negativeDeltaBorderColor: solid('#EEBD43'),
          }, {
            zoneTitleColor: solid('#364152'),
          }],
          background: [{
            show: true,
            color: solid('#fff'),
            transparency: 100,
          }],
        },
      },
      Datepicker_1687358625_Powerviz_OrgStore: {
        "*": {
          title: [{
            show: true,
            fontColor: solid('#364152'),
            alignment: "left",
            fontSize: 13,
            fontFamily: "arial",
          }],
        },
      },
      pageNavigator: {
        "*": {
          fill: [{
            show: true,
          }, {
            $id: "default",
            transparency: 0,
            fillColor: solid('#eef2f6'),
          }, {
            $id: "hover",
            transparency: 0,
            fillColor: solid('#0278cf3b'),
          }, {
            $id: "press",
            transparency: 0,
            fillColor: solid('#0078CF'),
          }, {
            $id: "selected",
            transparency: 0,
            fillColor: solid('#0078CF'),
          }, {
            $id: "disabled",
            transparency: 0,
            fillColor: solid('#FFFFFF'),
          }],
          text: [{
            show: true,
          }, {
            $id: "default",
            fontFamily: "arial",
            fontColor: solid('#0078CF'),
            horizontalAlignment: "center",
            verticalAlignment: "middle",
          }, {
            $id: "hover",
            fontColor: solid('#0078CF'),
            fontFamily: "arial",
          }, {
            $id: "press",
            fontColor: solid('#fff'),
            fontFamily: "arial",
          }, {
            $id: "selected",
            fontColor: solid('#fff'),
            fontFamily: "arial",
          }, {
            $id: "disabled",
            fontColor: solid('#FFFFFF'),
            fontFamily: "arial",
          }],
        },
      },
      mulltiMeasure6975758CD8B3457482EB5980A4ACDF83_OrgStore: {
        "*": {
          visual: [{
            titleFontSize: 14,
          }, {
            titleColor: solid('#687485'),
          }, {
            valueFontSize: 28,
          }, {
            valueColor: solid('#364152'),
          }, {
            unitsFontSize: 14,
          }, {
            unitsColor: solid('#364152'),
          }, {
            subtextFontSize: 14,
          }, {
            subtextColor: solid('#687485'),
          }, {
            backgroundColor: solid('#fff'),
          }, {
            borderColor: solid('#E3E8EF'),
          }],
          background: [{
            show: true,
            color: solid('#fff'),
            transparency: 0,
          }],
        },
      },
      "*": {
        "*": {
          outspacePane: [{
            backgroundColor: solid('#dadada'),
            foregroundColor: solid('#dadada'),
            checkboxAndApplyColor: solid('#dadada'),
          }],
          tooltips: [{
            fontFamily: "Arial, sans-serif",
            fontSize: 12,
          }],
          legend: [{
            labelColor: solid('#687485'),
            fontFamily: "arial",
          }],
          filterCard: [{
            $id: "Applied",
            backgroundColor: solid('#FCFDFE'),
            foregroundColor: solid('#323447'),
            transparency: 0,
          }, {
            $id: "Available",
            backgroundColor: solid('#F1F2F7'),
            foregroundColor: solid('#323447'),
            transparency: 0,
          }],
          background: [{
            color: solid('#fff'),
          }],
          visualHeader: [{
            foreground: solid('#0078CF'),
            border: solid('#fff'),
            background: solid('#fff'),
            transparency: 0,
            showVisualInformationButton: false,
            showVisualWarningButton: false,
            showVisualErrorButton: false,
            showDrillRoleSelector: false,
            showDrillUpButton: false,
            showDrillToggleButton: false,
            showDrillDownLevelButton: false,
            showDrillDownExpandButton: false,
            showPinButton: false,
            showFocusModeButton: false,
            showFilterRestatementButton: false,
            showSeeDataLayoutToggleButton: false,
            showOptionsMenu: true,
            showTooltipButton: false,
          }],
          visualHeaderTooltip: [{
            titleFontColor: solid('#687485'),
            fontFamily: "arial",
            transparency: 0,
            background: solid('#fff'),
          }],
          visualTooltip: [{
            titleFontColor: solid('#262735'),
            fontFamily: "arial",
            valueFontColor: solid('#262735'),
            background: solid('#F8FAF7'),
          }],
          dropShadow: [{
            position: "Outer",
            show: false,
            color: solid('#687485000'),
          }],
        },
      },
    },
    dataColors: [
      '#27aae1',
      '#78e1e8',
      '#0980bc',
      '#003975',
      '#238ab7',
      '#6fc2df',
    ],
    textClasses: {
      label: {
        fontFace: "arial",
        color: "#687485",
        fontSize: 10,
      },
      callout: {
        color: "#687485",
        fontSize: 9,
        fontFace: "arial",
      },
      title: {
        color: "#687485",
      },
      header: {
        color: "#687485",
        fontSize: 9,
        fontFace: "arial",
      },
    },
    bad: "#60CDFF",
    neutral: "#CCCCCC",
    good: "#005FB8",
    minimum: "#60CDFF",
    center: "#CCCCCC",
    maximum: "#005FB8",
  };

  return {
    light: lightTheme,
    dark: darkTheme
  };
}; 