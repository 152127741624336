import { createSlice } from "@reduxjs/toolkit";
import axios from "axios"; // this does not include app headers
import { dispatch } from "../index";
import axiosServices from "../../utils/axios";
import { storage } from "../../utils/helpers/storage";

// ----------------------------------------------------------------------

const parseVersion = (version) => {
  return version
    .split('.')
    .map(num => parseInt(num, 10) || 0); // Convert version parts to numbers
};

const compareVersions = (a, b) => {
  const versionA = parseVersion(a.version);
  const versionB = parseVersion(b.version);

  for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
    const numA = versionA[i] || 0; // Default to 0 if missing
    const numB = versionB[i] || 0;
    if (numA !== numB) {
      return numB - numA; // Descending order
    }
  }
  return 0;
};

export const getLatestRelease = (releases = []) => {
  if (!Array.isArray(releases) || releases.length === 0) {
    return null; // Return null safely if the input is not an array or is empty
  }

  // Sort releases based ONLY on version
  const sortedReleases = releases
    .filter(release => release.version) // Ensure version exists
    .sort(compareVersions);

  return sortedReleases[0] || null; // Return the latest release or null if none exist
};


export const initialState = {
  error: null,
  releases: [],
  lastRelease: null,
  showNewRelease: false,
  healthCheckToken: null,
  healthCheckPaths: [],
  serviceNames: {
    auth: "Authorization Service",
    activity: "Activity Service",
    analytics: "Analytics Service",
    config: "Configuration Service",
    notifications: "Notification Service",
    services: "General Service",
    tooling: "Tooling Service",
  },
};

const slice = createSlice({
  name: "help",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    getReleaseInfoSuccess(state, action) {
      state.releases = action.payload;
    },
    getAllReleaseSuccess(state, action) {
      const currentRelease = storage.getLastRelease()
      state.showNewRelease = currentRelease ? currentRelease != action.payload?.version : true
      state.lastRelease = action.payload?.version;
    },
    getLastReleaseSuccess(state, action) {
      state.showNewRelease = false;
      storage.setLastRelease(action.payload?.version)
      state.lastRelease = action.payload?.version;
    },
    getHealthCheckTokenSuccess(state, action) {
      state.healthCheckToken = action.payload;
    },
    getHealthCheckPathsSuccess(state, action) {
      state.healthCheckPaths = action.payload;
    },
  },
});
// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// store version in cookie
// do on user login
// nothing saved in coookie - show dot
// same no dot until get to page
// version # / have seen page
export function getReleaseInfo(majorVersion) {
  return async () => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/release/find?majorVersion=${majorVersion}`,
        {}
      );
      dispatch(slice.actions.getReleaseInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllReleaseInfo() {
  return async (dispatch) => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/release`
      );

      const latestRelease = getLatestRelease(response.data?.rows || []);

      if (latestRelease) {
        dispatch(slice.actions.getAllReleaseSuccess(latestRelease));
      } else {
        dispatch(slice.actions.hasError(new Error('No valid releases found')));
      }

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLastReleaseInfo() {
  return async (dispatch) => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/release`
      );

      const latestRelease = getLatestRelease(response.data?.rows || []);

      if (latestRelease) {
        dispatch(slice.actions.getLastReleaseSuccess(latestRelease));
      } else {
        dispatch(slice.actions.hasError(new Error('No valid releases found')));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getServiceHealthToken() {
  return async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API}/activity/activity/service/health`,
        {},
        {}
      );
      dispatch(slice.actions.getHealthCheckTokenSuccess(response.data.token));
      dispatch(
        slice.actions.getHealthCheckPathsSuccess(response.data.services)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getServiceHealth(token, service) {
  return async () => {
    try {
      return await axios.get(
        `https://management.azure.com/${service.path}/providers/Microsoft.ResourceHealth/availabilityStatuses/current?api-version=2022-10-01`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return error;
    }
  };
}
