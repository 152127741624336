import React, { memo, useEffect, useState } from "react";
import { IconButton, InputBase, Paper, Typography, FormControl, FormHelperText } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

const isValidFormat = (value, format) => {
  if (!format || !value) return true;
  const regex = new RegExp(format);
  return regex.test(value);
};

export const CustomizedSearchInput = memo(function CustomizedSearchInput({
  searchFilter,
  setSearchFilter,
  searchPlaceholder,
  maxWidth,
  modifier,
  format,
}) {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const [error, setError] = useState("");

  const handleActionClick = (event) => {
    event.preventDefault();
    if (isValidFormat(searchValue, format)) {
      setSearchFilter(searchValue);
      setError("");
    } else {
      setError("Input does not meet the required format");
    }
  };

  const handleClearInput = () => {
    setSearchValue("");
    setSearchFilter("");
    setError("");
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (modifier) {
      value = modifier(value);
    }
    setSearchValue(value);
    if (isValidFormat(value, format)) {
      setError("");
    } else {
      setError("Input does not meet the required format");
    }
  };

  useEffect(() => {
    if (searchFilter && searchFilter !== searchValue) {
      setSearchValue(searchFilter);
    }
  }, [searchFilter]);

  return (
    <FormControl fullWidth error={!!error}>
      <Paper
        component="form"
        onSubmit={handleActionClick}
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          maxWidth: maxWidth || 325,
          border: 1,
          borderColor: theme.palette.grey[300],
        }}
      >
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="search"
          color="primary"
          onClick={(e) => {
            e.preventDefault();
            handleActionClick(e);
          }}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ flex: 1 }}
          placeholder={searchPlaceholder || "Search"}
          inputProps={{ "aria-label": "search input" }}
          value={searchValue}
          onChange={handleInputChange}
          onKeyUp={(e) => {
            e.preventDefault();
            if (e.key === "Enter" || e.keyCode === 13) {
              handleActionClick(e);
            }
            if (e.key === "Escape") {
              handleClearInput();
            }
          }}
        />
        {searchValue && (
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="clear search"
            onClick={handleClearInput}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Paper>
      {error && (
        <FormHelperText>{error}</FormHelperText>
      )}
    </FormControl>
  );
});
