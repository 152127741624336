// Calculate average walking speed
const calculateAverageWalkingSpeed = (metricOrImperial) => {
  if (metricOrImperial === "Imperial") {
    return 3.8;
  } else {
    return 1.16;
  }
};

// Calculate wait task time based on pick density
const calculateWaitTaskTime = (pickDensity) => {
  const activeDensity = pickDensity.find(density => density.isActive);
  return activeDensity ? activeDensity.dwellTime : 0;
};

// Function to calculate static inputs
const calculateStaticInputs = (userInput, lookupReference) => {
  const staticInputs = {};

  // Calculate average walking speed
  staticInputs.averageWalkingSpeed = calculateAverageWalkingSpeed(userInput.metricOrImperial);

  // Calculate wait task time
  staticInputs.waitTaskTime = calculateWaitTaskTime(lookupReference.pickDensity);

  return staticInputs;
};

export { calculateStaticInputs };