import { jwtDecode } from "jwt-decode";
import axios from "../../utils/axios";
import { LOGOUT } from "../../store/actions";
import { storage } from "../../utils/helpers/storage";

let useDispatch;

/**
 * @param {string} domain
 * @returns {LocusClient}
 * */
export const getClientByDomain = async (domain) => {
  domain = domain
    .toLowerCase()
    .replace(/[^a-z0-9-]/g, "") // Remove invalid characters
    .replace(/^-+|-+$/g, "") // Remove leading or trailing hyphens
    .replace(/-+/g, "-") // Ensure only one hyphen is present
    .trim();
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVICES_API}/services/client/find?domain=${domain}`,
      {
        headers: {
          Authorization: "",
          "locus-region": "",
          "locus-domain": "",
        },
      },
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * @returns {LocusUser}
 * */
export const getUserInfo = async () => {
  const decoded = storage.getAccessToken()
    ? jwtDecode(storage.getAccessToken())
    : null;
  return await axios
    .get(`${process.env.REACT_APP_AUTH_API}/userinfo`, {
      headers: {
        "locus-domain": decoded?.domain,
        "locus-region": storage.getUserRegion()
          ? storage.getUserRegion()
          : storage.getRegion(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("[getUserInfo]", err);
      return err;
    });
};

export const getUserInfoWithUrl = async (user, url) => {
  return await axios
    .get(url, {
      headers: {
        "locus-domain": user.domain,
        "locus-region": storage.getUserRegion()
          ? storage.getUserRegion()
          : storage.getRegion(),
      },
    })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("[getUserInfo]", err);
    });
};
export const getGroupsForUser = (user) => {
  let groups = [];
  user?.groups?.forEach((item) => groups.push(item));
  return groups;
};
export const verifyToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  /**
   * Property 'exp' does not exist on type '<T = unknown>(token, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

export const revokeToken = async () => {
  if (!storage.getRefreshToken()) return;
  const decoded = storage.getAccessToken()
    ? jwtDecode(storage.getAccessToken())
    : null;
  let response = await axios
    .post(
      `${process.env.REACT_APP_AUTH_API}/oauth/revoke`,
      {
        client_id: decoded?.username,
        token: storage.getRefreshToken(),
      },
      {
        headers: {
          "locus-domain": decoded?.domain,
          "locus-region": storage.getUserRegion()
            ? storage.getUserRegion()
            : storage.getRegion(),
        },
      },
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return err;
    });
  return response;
};
export const removeSession = () => {
  storage.removeAll();
  clearTimeout(window.powerBiRefreshToken);
  clearTimeout(window.powerBiRefreshData);
  delete axios.defaults.headers.common.Authorization;
  delete axios.defaults.headers["locus-domain"];
  delete axios.defaults.headers["locus-region"];
};

export const refreshTokenApi = async (dispatch, refreshToken, clientId) => {
  // Assume `/refresh` is the endpoint for refreshing tokens
  try {
    const decoded = storage.getAccessToken()
      ? jwtDecode(storage.getAccessToken())
      : null;
    return await axios.post(
      `${process.env.REACT_APP_AUTH_API}/oauth/token?grant_type=refresh_token`,
      { refresh_token: refreshToken, client_id: clientId },
      {
        headers: {
          "locus-region": storage.getUserRegion()
            ? storage.getUserRegion()
            : storage.getRegion(),
          "locus-domain": decoded?.domain,
        },
      },
    );
  } catch (error) {
    if (!storage.getKiosk()) {
      removeSession();
      dispatch({
        type: LOGOUT,
      });
      console.error("refreshTokenApi error", error);
      return error;
    } else {
      // do not remove session for kiosks if there is an error
      return error;
    }
  }
};

export const setSession = (dispatch, accessToken, refreshToken) => {
  useDispatch = dispatch;

  if (accessToken && refreshToken) {
    storage.setAccessToken(accessToken);
    storage.setRefreshToken(refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers["locus-domain"] = storage.getDomain()
      ? storage.getDomain()
      : null;
    axios.defaults.headers["locus-region"] = storage.getRegion()
      ? storage.getRegion()
      : null;
  } else {
    removeSession();
  }
};
