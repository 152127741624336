import { models } from "powerbi-client";
import { generatePowerBIThemes } from "../../assets/powerbi-themes/theme-generator";

// Cache for generated themes with theme key
const themeCache = new Map();

// Helper to clear theme cache when MUI theme changes
export const clearThemeCache = () => {
  themeCache.clear();
};

export const PowerBIReportState = {
  PENDING: "pending",
  LOADED: "loaded",
  RENDERED: "rendered",
  ERROR: "error",
};

export const getInitialFilter = (databaseName) => {
  return {
    $schema: "http://powerbi.com/product/schema#advanced",
    target: {
      table: "databaseInfo",
      column: "databaseSelected",
    },
    filterType: models.FilterType.Advanced,
    logicalOperator: "Or",
    conditions: [
      {
        operator: "Is",
        value: databaseName,
      },
    ],
  };
};

export const getDisplayOptionFromKioskMode = (kioskMode) => {
  return kioskMode
    ? models.DisplayOption.FitToPage
    : models.DisplayOption.FitToWidth;
};

export const getBookmarkNameFromTheme = (theme, selectedDashboard) => {
  return theme === "dark" && selectedDashboard?.darkBookmark
    ? selectedDashboard.darkBookmark
    : "";
};

export const getThemeFileFromTheme = (theme, muiTheme) => {
  if (!muiTheme) {
    return null; // Return null instead of empty object to trigger fallback behavior
  }

  // Always generate new themes to ensure we have the latest MUI theme values
  const themes = generatePowerBIThemes(muiTheme);

  const resultTheme = theme === "dark" ? themes.dark : themes.light;

  if (!resultTheme) {
    return null;
  }

  return resultTheme;
};

export const getDisplayLayoutType = () => {
  return window.innerWidth <= 660 &&
    window.matchMedia("(orientation: portrait)").matches
    ? models.LayoutType.MobilePortrait
    : models.LayoutType.Custom;
};

export const getInitialReportConfig = (
  filters,
  slicers,
  kioskMode,
  theme,
  selectedPage,
  muiTheme,
) => {
  return {
    type: "report",
    embedUrl: undefined,
    tokenType: models.TokenType.Embed,
    accessToken: undefined,
    id: "",
    permissions: 0,
    datasetBinding: {
      datasetId: "notOriginalDatasetId",
    },
    settings: {
      panes: {
        filters: {
          expanded: false,
          visible: false,
        },
        pageNavigation: {
          visible: false,
        },
      },
      layoutType: getDisplayLayoutType(),
      customLayout: {
        displayOption: getDisplayOptionFromKioskMode(kioskMode),
      },
    },
    bookmark: {},
    theme: {
      themeJson: getThemeFileFromTheme(theme, muiTheme),
    },
    slicers: slicers || [],
    filters: [filters],
    pageName: selectedPage,
  };
};

export const getHydratedReportConfig = ({
  data,
  filters,
  slicers,
  reportDisplaySettings,
  selectedPage,
  theme,
  muiTheme,
}) => {
  const config = {
    type: "report",
    tokenType: models.TokenType.Embed,
    permissions: 0,
    pageName: selectedPage,
    embedUrl: data.embedUrl,
    accessToken: data.token,
    id: data.reportId,
    datasetBinding: {
      datasetId: data.datasetId,
    },
    slicers: slicers?.length ? slicers : [],
    filters: filters,
    theme: muiTheme
      ? { themeJson: getThemeFileFromTheme(theme, muiTheme) }
      : undefined,
    ...reportDisplaySettings,
  };

  return config;
};
export const getParamFilters = () => {
  const params = new URLSearchParams(window.location.search);

  return Array.from(params.keys()).reduce(
    (acc, val) => ({ ...acc, [val]: params.get(val) }),
    {},
  );
};
export const hydrateFilter = (item) => {
  let filter;
  if (item.filterType === "basic") {
    filter = {
      selector: {
        $schema: "http://powerbi.com/product/schema#slicerTargetSelector",
        target: {
          table: item.table,
          column: item.column,
        },
      },
      state: {
        filters: [
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: item.table,
              column: item.column,
            },
            operator: item.operator,
            values: item.values,
            filterType: models.FilterType.Basic,
          },
        ],
      },
    };
  } else if (item.filterType === "advanced") {
    filter = {
      selector: {
        $schema: "http://powerbi.com/product/schema#advanced",
        target: {
          table: item.table,
          column: item.column,
        },
      },
      state: {
        filters: [
          {
            $schema: "http://powerbi.com/product/schema#advanced",
            target: {
              table: item.table,
              column: item.column,
            },
            // operator: item.operator,
            logicalOperator: item.operator,
            // values: item.values,
            conditions: item.values,
            filterType: models.FilterType.Advanced,
          },
        ],
      },
    };
  }
  return filter;
};

export const addDatasetToFilters = (filters, db) => {
  let _filters = filters;
  _filters.conditions[0].value = db;
  return _filters;
};
export const isRefreshTokenExpiring = (tokenExpiration) => {
  // Get the current time
  const currentTime = Date.now();
  const expiration = Date.parse(tokenExpiration);

  // Time until token expiration in milliseconds
  const timeUntilExpiration = expiration - currentTime;
  const timeToUpdate = 10 * 60 * 1000;

  // `true` if expiring
  return timeUntilExpiration <= timeToUpdate;
};
