import { ProtectedElement } from "../ProtectedRoute";
import { Loadable } from "../../components/Loadable/Loadable";
import { lazy } from "react";
import {
  LOCUS_TENANT,
  LOCUS_TOOLS_ADMINISTRATOR,
  LOCUS_TOOLS_USER,
  SUPER_ADMIN,
  SYSTEM_INTEGRATOR,
} from "../../utils/constants/user";
import componentLoader from "../../utils/helpers/componentLoader";
import SiteConfiguration from "views/site-configuration/SiteConfiguration";

const UpdateConfigurationFiles = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/tools/site-actions/update-configuration-files"),
    ),
  ),
);
const SiteConfigView = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/tools/site-actions/update-configuration-files/view"),
    ),
  ),
);
const DeviceConfiguration = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/tools/device-configuration/DeviceConfiguration"),
    ),
  ),
);
const IntegrationMonitoring = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/IntegrationMonitoring")),
  ),
);
const CancelJob = Loadable(
  lazy(() =>
    componentLoader(
      () => import("views/tools/site-actions/cancel-job/CancelJob"),
    ),
  ),
);
const SiteActions = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/SiteActions")),
  ),
);
const ActionsOverview = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/ActionsOverview")),
  ),
);

const ClearCart = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/actions/ClearCart")),
  ),
);

const ClearTote = Loadable(
  lazy(() =>
    componentLoader(() => import("views/tools/site-actions/clear-tote/ClearTote")),
  ),
);

const FleetAdmin = Loadable(
  lazy(() => componentLoader(() => import("views/tools/FleetAdmin"))),
);

const BotCalculatorPage = Loadable(
  lazy(() =>
    componentLoader(
      () =>
        import(
          "views/tools/system-integration/bot-calculator/BotCalculatorPage"
        ),
    ),
  ),
);

const BotCalculator = Loadable(
  lazy(() =>
    componentLoader(
      () =>
        import("views/tools/system-integration/bot-calculator/BotCalculator"),
    ),
  ),
);

export const ToolsRoutes = {
  path: "tools",
  children: [
    // {
    //   path: "site-configuration",
    //   element: (
    //     <ProtectedElement
    //       element={SiteConfiguration}
    //       allowedGroups={[LOCUS_TOOLS_USER]}
    //     />
    //   ),
    //   breadcrumb: {
    //     display: "Site Configuration",
    //   },
    // },
    {
      path: "site-actions",
      breadcrumb: {
        display: "Site Actions",
      },
      children: [
        {
          path: "",
          element: (
            <ProtectedElement
              element={SiteActions}
              allowedGroups={[
                SUPER_ADMIN,
                LOCUS_TOOLS_USER,
                LOCUS_TOOLS_ADMINISTRATOR,
              ]}
            />
          ),
        },
        {
          path: "actions-overview",
          breadcrumb: {
            display: "Actions Overview",
          },
          children: [
            {
              path: "",
              element: (
                <ProtectedElement
                  element={ActionsOverview}
                  allowedGroups={[
                    SUPER_ADMIN,
                    LOCUS_TOOLS_USER,
                    LOCUS_TOOLS_ADMINISTRATOR,
                  ]}
                />
              ),
            },
            {
              path: "clear-cart",
              element: (
                <ProtectedElement
                  element={ClearCart}
                  allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_ADMINISTRATOR, LOCUS_TOOLS_USER]}
                />
              ),
              breadcrumb: {
                display: "Clear Cart",
              },
            },
            {
              path: "clear-tote",
              element: (
                <ProtectedElement
                  element={ClearTote}
                  allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_ADMINISTRATOR]}
                />
              ),
              breadcrumb: {
                display: "Clear Tote",
              },
            },
          ],
        },
        {
          path: "update-configuration-files",
          element: (
            <ProtectedElement
              element={UpdateConfigurationFiles}
              allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_ADMINISTRATOR]}
            />
          ),
          breadcrumb: {
            display: "Update Configuration Files",
          },
        },
        {
          path: "update-configuration-files/view/:floorId",
          element: (
            <ProtectedElement
              element={SiteConfigView}
              allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_ADMINISTRATOR]}
            />
          ),
        },
        {
          path: "cancel-job",
          element: (
            <ProtectedElement
              element={CancelJob}
              allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_ADMINISTRATOR]}
              optionalGroups={[LOCUS_TENANT, LOCUS_TOOLS_USER]}
            />
          ),
          breadcrumb: {
            display: "Cancel Job",
          },
        },
      ],
    },
    {
      path: "site-configuration",
      element: (
        <ProtectedElement
          element={SiteConfiguration}
          allowedGroups={[SUPER_ADMIN, LOCUS_TOOLS_ADMINISTRATOR, LOCUS_TOOLS_USER]}
        />
      ),
      breadcrumb: {
        display: "Site Configuration",
      },
    },
    {
      path: "device-configuration",
      element: (
        <ProtectedElement
          element={DeviceConfiguration}
          allowedGroups={[LOCUS_TOOLS_USER, LOCUS_TOOLS_ADMINISTRATOR]}
        />
      ),
      breadcrumb: {
        display: "iPad Access",
      },
    },
    {
      path: "device-configuration/:clientId",
      element: (
        <ProtectedElement
          element={DeviceConfiguration}
          allowedGroups={[LOCUS_TOOLS_USER, LOCUS_TOOLS_ADMINISTRATOR]}
        />
      ),
      breadcrumb: {
        display: "iPadAccess",
      },
    },
    {
      path: "integration-monitoring",
      element: (
        <ProtectedElement
          element={IntegrationMonitoring}
          allowedGroups={[LOCUS_TOOLS_USER, LOCUS_TOOLS_ADMINISTRATOR]}
        />
      ),
      breadcrumb: {
        display: "Integration Monitoring",
      },
    },
    {
      path: "fleet-admin",
      breadcrumb: {
        display: "Fleet Admin",
      },
      element: <FleetAdmin />,
    },
    {
      path: "system-integration",
      breadcrumb: {
        display: "System Integration",
      },
      children: [
        {
          breadcrumb: {
            display: "Bot Calculator",
          },
          path: "bot-calculator",
          element: (
            <ProtectedElement
              element={BotCalculatorPage}
              allowedGroups={[SUPER_ADMIN, SYSTEM_INTEGRATOR]}
            />
          ),
        },
        {
          path: "bot-calculator/edit/:id/:version",
          breadcrumb: {
            display: "Edit Bot Calculator",
          },
          element: (
            <ProtectedElement
              element={BotCalculator}
              allowedGroups={[SUPER_ADMIN, SYSTEM_INTEGRATOR]}
            />
          ),
        },
        {
          path: "bot-calculator/add",
          breadcrumb: {
            display: "New Bot Calculator",
          },
          element: (
            <ProtectedElement
              element={BotCalculator}
              allowedGroups={[SUPER_ADMIN, SYSTEM_INTEGRATOR]}
            />
          ),
        },
      ],
    },
  ],
};
